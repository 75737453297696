import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import { routes } from "../../constants/routes";
import { ethers } from "ethers";
import { Button, Card } from "react-bootstrap";
import { injectModels } from "../../redux/injectModels";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

const Header = (props) => {
  const history = useHistory();
  const { currentUser } = useAuth();
  const [copySuccess, setCopySuccess] = useState("");
  // usetstate for storing and retrieving wallet details
  // const [walletdata, setWalletData] = useState({
  //   address: null,
  //   balance: null,
  // });
  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState("");
  const [conButtonText, SetConButtonText] = useState("Connect Wallet");

  const walletType = process.env.REACT_APP_WALLET_TYPE_METAMASK;

  useEffect(() => {
    if (localStorage.getItem("wallet")) {
      SetConButtonText("Disconnect");
      getbalance(localStorage.getItem("wallet"));
      setAddress(localStorage.getItem("wallet"));
    } else {
      SetConButtonText("Connect Wallet");
    }
  }, []);

  const updateDimensions = () => {
    if (window.innerWidth < 768) {
      var element = document.getElementsByClassName(
        "walletconnect-modal__mobile__toggle"
      );

      if (element.length > 0) {
        element[0].lastChild.click();
      }
    }
  };

  const connect = async () => {
    setTimeout(updateDimensions, 100);
    try {
      const paramWC = {
        rpc: {
          [process.env
            .REACT_APP_BLOCKCHAIN_ID]: `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`,
        },
        mobileLinks: [],
      };
      const provider = new WalletConnectProvider(paramWC);

      const response = await provider.enable();

      // getBalance(response[0])
      // alert(response[0]);
      // accountChangeHandler(response[0]);

      localStorage.setItem(
        "wallet_type",
        `${process.env.REACT_APP_WALLET_TYPE_WALLET_CONNECT}`
      );
      localStorage.setItem("wallet", response[0]);
      //SetConButtonText("Disconnect");
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const handleToggle = () => {
    const list = document.querySelector("body").classList;
    //console.log(list.contains,"list");
    if (list.contains("toggle-sidebar")) {
      list.remove("toggle-sidebar");
    } else {
      list.add("toggle-sidebar");
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();

    const list = document.getElementById("header-user-drop-down").classList;
    if (list.contains("open")) {
      list.remove("open");
    } else {
      list.add("open");
    }
  };

  const copyToClipBoard = async (Uid) => {
    try {
      await navigator.clipboard.writeText(Uid);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const btnhandler = () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => accountChangeHandler(res[0]));
    } else {
      connect();
    }
  };

  const getbalance = async (account) => {
    const walletAddress = localStorage.getItem("wallet");
    const { ethereum } = window;
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        web3.eth.getBalance(walletAddress, (err, bal) => {
          if (err) {
            return console.log(err);
          }
          setBalance(ethers.utils.formatUnits(bal.toString(), "ether"));
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(
            `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`
          )
        );
        web3.eth.getBalance(account, (err, bal) => {
          if (err) {
            return console.log(err);
          }
          setBalance(ethers.utils.formatUnits(bal.toString(), "ether"));
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const accountChangeHandler = (account) => {
    localStorage.setItem("wallet", account);
    localStorage.setItem(
      "wallet_type",
      process.env.REACT_APP_WALLET_TYPE_METAMASK
    );

    toast.success("Connect with MetaMask!", {
      position: toast.POSITION.TOP_CENTER,
    });

    setAddress(account);
    console.log("this is address ", account);

    // Setting a balance
    getbalance(account);
    window.location.reload();
  };

  const disconnect = () => {
    localStorage.setItem("wallet", "");
    localStorage.setItem("wallet_type", "");
    localStorage.setItem("walletconnect", "");
    SetConButtonText("Connect Wallet");
    window.location.reload();
  };

  const data = JSON.stringify(currentUser && currentUser.email);
  const Uid = JSON.stringify(currentUser && currentUser.uid);

  return (
    <header
      id="header"
      className="dash-header fixed-top d-flex align-items-center"
    >
      <div className="d-flex align-items-center justify-content-between logo-main-header">
        <Link to="/" className="dash-logo d-flex align-items-center">
          <img src="assets/dashboard/assets/img/logo.png" alt="#" />
        </Link>
        <i
          className="fa-solid fa-bars toggle-sidebar-btn"
          onClick={handleToggle}
        ></i>
      </div>

      <nav className="header-nav ms-auto ">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown pe-3">
            <Link
              className=" nav-link nav-icon nav-profile d-flex align-items-center pe-0"
              to={routes.DEFAULT}
            >
              <i className="fa-solid fa-home" />
            </Link>
          </li>
          {/* <li className="nav-item dropdown" id="header-user-drop-down">
          <Link
            className="nav-link nav-icon"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="true"
            onClick={handleDrop}
          >
            <i className="fa-solid fa-bell" />
            <span className="badge  badge-number">4</span>
          </Link>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
            <li className="dropdown-header">
              You have 4 new notifications
              <Link to="#">
                <span className="badge rounded-pill bg-primary p-2 ms-2">
                  View all
                </span>
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="notification-item">
              <i className="bi bi-exclamation-circle text-warning" />
              <div>
                <h4>Lorem Ipsum</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>30 min. ago</p>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="notification-item">
              <i className="bi bi-x-circle text-danger" />
              <div>
                <h4>Atque rerum nesciunt</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>1 hr. ago</p>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="notification-item">
              <i className="bi bi-check-circle text-success" />
              <div>
                <h4>Sit rerum fuga</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>2 hrs. ago</p>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="notification-item">
              <i className="bi bi-info-circle text-primary" />
              <div>
                <h4>Dicta reprehenderit</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>4 hrs. ago</p>
              </div>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li className="dropdown-footer">
              <Link to="#">Show all notifications</Link>
            </li>
          </ul>
        </li> */}
          <li className="nav-item dropdown pe-3">
            <Link
              className=" nav-link nav-icon nav-profile d-flex align-items-center pe-0"
              to="#"
              data-bs-toggle="dropdown"
            >
              <i className="fa-solid fa-user" />
              {/* <span class="d-none d-md-block dropdown-toggle ps-2">K. Anderson</span> */}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <div className="merchant-id-user">
                  <div className="dotpay-login">
                    <i className="fa-regular fa-user"></i>
                    <h6>Dot Pay</h6>
                  </div>
                </div>
                <div className="merchant-id-button">
                  <button onClick={() => copyToClipBoard(Uid)}>
                    {copySuccess} {Uid}{" "}
                  </button>
                </div>
                <p>merchant Id (click for copy)</p>
                <div className="login-email">
                  <p>Logged in as</p>
                  <h5>{data}</h5>
                  <div className="submit-button2">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        props.application.setLoading(true);
                        props.auth.logout();
                        props.application.setLoading(false);
                        history.push("/");
                        console.log("Logged out");
                      }}
                      className="btn btn-primary"
                    >
                      LogOut
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          <li className="nav-item dropdown pe-3">
            <Link
              className=" nav-link nav-icon nav-profile d-flex align-items-center pe-0 "
              to="#"
              data-bs-toggle="dropdown"
            >
              <i className="fa-solid fa-wallet" />
              {/* <span class="d-none d-md-block dropdown-toggle ps-2">K. Anderson</span> */}
            </Link>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <Card className="text-center">
                  <Card.Header>
                    <strong>Address: </strong>
                    {address && address}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <strong>Balance: </strong>
                      {balance}
                    </Card.Text>

                    {/* <Button onClick={btnhandler} variant="primary">
                   {conButtonText}
                  </Button>
                <Button onClick={disconnect} variant="primary">
                 {conButtonText}
                  </Button> */}

                    {conButtonText === "Connect Wallet" ? (
                      <Button onClick={btnhandler} variant="primary">
                        {conButtonText}
                      </Button>
                    ) : (
                      <Button onClick={disconnect} variant="primary">
                        {conButtonText}
                      </Button>
                    )}
                  </Card.Body>
                </Card>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default injectModels(["auth", "application"])(Header);
