import React from 'react';
import { Header, Footer } from '../components';

const PublicLayout = (props) => {
    return (
      <React.Fragment>
      <Header />
      <main className="main-wrapper">
       {props.children}
       </main>
      <Footer />
   </React.Fragment>
     );
};

export default PublicLayout;