import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import { useAuth } from "../../../contexts/AuthContext";
import { injectModels } from "../../../redux/injectModels";

const PanRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const PinRegex = /^\d{4}$|^\d{6}$/;

const KycForm3 = (props) => {

  const { currentUser } = useAuth();
  const [docdata, setdocData] = useState([]);
  const [pan, setPan] = useState("");
  const [name, setName] = useState("");
  const [billinglabel, setBillingLabel] = useState("");
  const [pincode, setPinCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [panError, setPanError] = useState("");
  const [nameError, setNameError] = useState("");
  const [pincodeError, setPinCodeError] = useState("");
  const [billinglabelError, setBillingLabelError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [walletError, setWalletError] = useState("");
  const [wallet, setWallet] = useState("");

  const userId = currentUser && currentUser.uid;

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      console.log(data);
      setdocData(data);
    } else {
      console.log("No such document!");
    }
  };
  console.log(docdata.uid, "userId");

  useEffect(() => {
    if (currentUser != null) {
      getData();
    }
  }, [currentUser]);



  const handlePanChange = (e) => {
    e.preventDefault();
    const val = e.target.value.toUpperCase();
    if (val.trim() === "") {
      setPanError("Please fill out this field");
    } else if (!PanRegex.test(val)) {
      setPanError("Invalid Pan Card");
    } else {
      setPanError("");
    }
    setPan(val.trim());
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setNameError("Please fill out this field");
    } else {
      setNameError("");
    }
    setName(val.trim());
  };

  const handleBilingLabelChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setBillingLabelError("Please fill out this field");
    } else {
      setBillingLabelError("");
    }
    setBillingLabel(val.trim());
  };

  const handlePinCodeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setPinCodeError("Please fill out this field");
    } else if (!PinRegex.test(val)) {
      setPinCodeError("Please enter 6 digit pincode");
    } else {
      setPinCodeError("");
    }
    setPinCode(val.trim());
  };
  
  const handleWalletAddress = (e) => {
    e.preventDefault();
    const val = e.target.value;
    // const wallet = "0x1D3386566e70EB12268d664a044916C8F42C75b6";
    const st = val.slice(0, 2);
    const lt = "0x";
    if (st === lt) {
      if (e.target.value.length > e.target.maxLength)
        e.target.value = e.target.value.slice(0, e.target.maxLength);

      if (val.trim() === "") {
        setWalletError("You need to add Verifier Wallet Address");
      } else {
        setWalletError("");
      }
      setWallet(val.trim());
    } else {
      setWalletError("Please enter valid Wallet Address");
    }
  };

  const handleCityChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setCityError("Please fill out this field");
    } else {
      setCityError("");
    }
    setCity(val.trim());
  };



  const handleStateChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setStateError("Please fill out this field");
    } else {
      setStateError("");
    }
    setState(val.trim());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;
    if (name === "") {
      setNameError("Please fill out this field");
      valid = false;
    } else {
      setNameError("");
    }
    if (pan === "") {
      setPanError("Please fill out this field");
      valid = false;
    } else {
      setPanError("");
    }
    if (wallet === "") {
      setWalletError("Please fill out this field");
      valid = false;
    } else {
      setWalletError("");
    }
    if (billinglabel === "") {
      setBillingLabelError("Please fill out this field");
      valid = false;
    } else {
      setBillingLabelError("");
    }
    if (pincode === "") {
      setPinCodeError("Please fill out this field");
      valid = false;
    } else {
      setPinCodeError("");
    }
    if (city === "") {
      setCityError("Please fill out this field");
      valid = false;
    } else {
      setCityError("");
    }
    if (state === "") {
      console.log(state, "state");
      setStateError("Please select your state");
      valid = false;
    } else {
      setStateError("");
    }

    if (!valid) {
      toast.error("Form is not Valid", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else {
      props.application.setLoading(true)
      updateDoc(doc(db, "Kyc", docdata.uid), {
        Pan: pan,
        PanholderName: name,
        BillingLabel: billinglabel,
        WalletAddress: wallet,
        Pincode: pincode,
        City: city,
        State: state,
      })
        .then(() => {
          updateDoc(doc(db, "Merchants", docdata.uid), {
            isKycComplete: true,
          })
            .then(() => {
              props.application.setLoading(false)
             
              toast.info("KYC submitted! it's under process ", {
                position: toast.POSITION.TOP_CENTER,
              });
              window.setTimeout(function(){window.location.reload()},5000)
            })
            .catch((e) => {
              props.application.setLoading(false)
              const errorMessage = e.code.split("auth")[1];
              toast.error(errorMessage, {
                position: toast.POSITION.TOP_CENTER,
              });
            });
        })
        .catch((e) => {
          const errorMessage = e.code.split("auth")[1];
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
          <React.Fragment>
            <div className="form-content">
              <div className="col-md-8">
                <div className="merchant-kyc-div Info">
                  <h2>BUSINESS DETAILS </h2>
                  <form>
                    <div className="mb-3">
                      <label className="form-label">
                        PAN<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        onChange={handlePanChange}
                      />
                      {panError && <span className="error">{panError}</span>}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        PAN Holder's Name
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleNameChange}
                      />
                      {nameError && <span className="error">{nameError}</span>}
                      <p className="small">
                        We verify the details with the central PAN database.
                        Please ensure you enter the correct details
                      </p>
                    </div>
                    <div className="form-group">
                      <label>
                        Billing label<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        cols="50"
                        defaultValue={""}
                        onChange={handleBilingLabelChange}
                      />
                      {billinglabelError && (
                        <span className="error">{billinglabelError}</span>
                      )}
                      <p className="small">
                        enter the brand name your customers are familier with or
                        you want to use in future
                      </p>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        Wallet Address
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="walletAddress"
                        onChange={handleWalletAddress}
                      />
                      {walletError && (
                        <span className="error">{walletError}</span>
                      )}
                    </div>

                    <div className="mb-3">
                      <label className="form-label">
                        PinCode<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        onChange={handlePinCodeChange}
                      />
                      {pincodeError && (
                        <span className="error">{pincodeError}</span>
                      )}
                    </div>
                    <div className="form-group mb-3">
                      <label>
                        State<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={handleStateChange}
                      >
                        <option value>--Select--</option>
                        <option value="AN">Andaman And Nicobar</option>
                        <option value="AP">Andhra Pradesh</option>
                        <option value="AR">Arunachal Pradesh</option>
                        <option value="AS">Assam</option>
                        <option value="BI">Bihar</option>
                        <option value="CH">Chandigarh (UT)</option>
                        <option value="CT">Chattisgarh</option>
                        <option value="DN">Dadra And Nagar Haveli</option>
                        <option value="DD">Daman And Diu (UT)</option>
                        <option value="DL">Delhi</option>
                        <option value="GO">Goa</option>
                        <option value="GJ">Gujarat</option>
                        <option value="HA">Haryana</option>
                        <option value="HP">Himachal Pradesh</option>
                        <option value="JK">Jammu And Kashmir</option>
                        <option value="JH">Jharkhand</option>
                        <option value="KA">Karnataka</option>
                        <option value="KE">Kerala</option>
                        <option value="LD">Lakshadweep</option>
                        <option value="MP">Madhya Pradesh</option>
                        <option value="MH">Maharashtra</option>
                        <option value="MA">Manipur</option>
                        <option value="ME">Meghalaya</option>
                        <option value="MI">Mizoram</option>
                        <option value="NA">Nagaland</option>
                        <option value="OR">Orissa</option>
                        <option value="PO">Pondicherry(UT)</option>
                        <option value="PB">Punjab</option>
                        <option value="RJ">Rajasthan</option>
                        <option value="SK">Sikkim</option>
                        <option value="TG">Telangana</option>
                        <option value="TN">Tamilnadu</option>
                        <option value="TR">Tripura</option>
                        <option value="UP">Uttar Pradesh</option>
                        <option value="UT">Uttranchal</option>
                        <option value="WB">West Bengal</option>
                      </select>
                      {stateError && (
                        <span className="error">{stateError}</span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">
                        City<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleCityChange}
                      />
                      {cityError && <span className="error">{cityError}</span>}
                    </div>
                  
                    {" "}
                    <div className="checkbox-input-new">
                    <input
                      className=" pr-3"
                      id="check"
                      type="checkbox"
                    />
                    <p>
                    I agree to DotPay Terms and Conditions
                  </p></div>
                  </form>
                </div>
                <div className="ftr-form">
                  <button
                    className="save-next btn btn-primary Button"
                    onClick={handleSubmit}
                  >
                    Submit and Verify
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
  );
};

export default injectModels(['application'])(KycForm3) ;


/* file created by sourav mishra  08/7/2022 */