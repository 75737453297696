import { BrowserRouter as Router, Switch} from "react-router-dom";
import { Routes } from './constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomAuthRoute from "./CustomAuthRoute";
import CustomDashboardRoute from "./CustomDashboardRoute";
import CustomPublicRoute from "./CustomPublicRoute";
import * as COMPONENTS from "./components";
import AuthContextProvider from "./contexts/AuthContext";
import React from "react";
import { injectModels } from "./redux/injectModels";
import Loader from "./components/Loader/loader";

function App() { 

  return (
    <React.Fragment> 
      <ToastContainer />
      <Loader />
     <Router>
     <AuthContextProvider>
       <Switch>
       <CustomAuthRoute exact path={Routes.LOGIN} component={COMPONENTS.Login} />
       <CustomAuthRoute exact path={Routes.SIGNUPONE} component={COMPONENTS.Signup} />
       <CustomAuthRoute exact path={Routes.FORGOTPASSWORD} component={COMPONENTS.ForgotPassword} />
       <CustomAuthRoute exact path={Routes.RESETPASSWORD} component={COMPONENTS.ResetPassword} />
       <CustomDashboardRoute exact path={Routes.DASHBOARD} component= {COMPONENTS.Dashboard } />
       <CustomDashboardRoute exact path={Routes.PROFILE} component= {COMPONENTS.Profile } />
       <CustomDashboardRoute exact path={Routes.EDITPROFILE} component= {COMPONENTS.EditProfile } />
       <CustomDashboardRoute exact path={Routes.PAYMENTS} component= {COMPONENTS.TransactionPage } />
       <CustomDashboardRoute exact path={Routes.SUBSCRIPTIONS} component= {COMPONENTS.Subscription } />
       <CustomDashboardRoute exact path={Routes.OFFERS} component= {COMPONENTS.Offer } />
       <CustomDashboardRoute exact path={Routes.API} component= {COMPONENTS.Api } />
       <CustomPublicRoute exact path={Routes.MISSION} component= {COMPONENTS.Mission } />
       <CustomPublicRoute exact path={Routes.ABOUTUS} component= {COMPONENTS.Aboutus } />
       <CustomPublicRoute exact path={Routes.TERMCONDITIONS} component= {COMPONENTS.TermsCondition } />
       <CustomPublicRoute exact path={Routes.BLOGS} component= {COMPONENTS.Blogs } />
       <CustomPublicRoute exact path={Routes.PRIVACY} component= {COMPONENTS.Privacy } />
       <CustomDashboardRoute exact path={Routes.PHONEVERIFIED} component={COMPONENTS.PhoneVerified} />
       <CustomPublicRoute exact path={Routes.DEFAULT} component={COMPONENTS.Home} />
       <CustomPublicRoute exact path={Routes.EXPLORE} component={COMPONENTS.Explore} />
       <CustomPublicRoute exact path={Routes.NOT_FOUND} component={COMPONENTS.NotFound} />
       <CustomPublicRoute exact path={Routes.CONTACT} component={COMPONENTS.Contact} />
       <CustomPublicRoute exact path={Routes.FAQ} component={COMPONENTS.Faq} />
       <CustomPublicRoute exact path={Routes.ERROR} component={COMPONENTS.Error} />
       </Switch> 
       </AuthContextProvider>  
     </Router>
     </React.Fragment>
  );
}

export default injectModels(['application']) (App) ;
