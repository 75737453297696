import React from 'react'
import Banner from "./Banner";
// import CreateInfo from './CreateInfo';
import InfoBanner from "./InfoBanner";
import Intro from './Intro';
import Introduction from './Introduction';

const Home = () => {
  return (
        <React.Fragment>
         <Banner />
         <InfoBanner />
         <Introduction />
         {/* <CreateInfo /> */}
         <Intro />
         </React.Fragment>
  );
}

export default Home;

/* file created by sourav mishra */