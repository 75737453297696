import React from 'react';
import {Route} from 'react-router-dom';
import { PublicLayout } from './layouts';



const CustomPublicRoute = ({component:Component, ...rest}) => { console.log("public")

return (
        <Route {...rest} render={(props)=>{
            return (
                <React.Fragment>
                  {<PublicLayout><Component {...props} /></PublicLayout>}
                </React.Fragment>
            )
        }} />
    );
};

export default CustomPublicRoute;