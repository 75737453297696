import React from 'react'

const InfoBanner = () => {
  return (
            <section className="offer-trusted">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <div className="card-secure">
                        <img alt='' src="/assets/img/easy.png"/>
                        <h6>Easy To Transact</h6>
                        <p>Transfer your business funds in no time. The transfers are instantaneous, carry no fee, work anywhere in the world, and are private. </p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card-secure">
                        <img alt='' src="/assets/img/bitc.png" />
                        <h6>No Fear Of Loss</h6>
                        <p> Unlike any form of payment transfer, Dotpay is independent, transparent, and completely decentralized with no third-party involvement.</p>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card-secure">
                        <img alt='' src="/assets/img/secure.png" />
                        <h6>Full proof Security</h6>
                        <p> DotPay is based on distributed ledger technology. It is considered to be one of the best ways of securing business transactions. </p>
                    </div>
                </div>
            </div>
        </div>
        </section>
  )
}

export default InfoBanner