import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Routes } from "../../constants";
import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import KycModel from "./KycModel";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase/config";
import { injectModels } from "../../redux/injectModels";

const SideBar = (props) => {
  const { currentUser } = useAuth();
  const [docdata, setdocData] = useState([]);
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const history = useHistory();

  const userId = currentUser && currentUser.uid;

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    props.application.setLoading(true);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
    props.application.setLoading(false);
      setdocData(data);
    } else {
      console.log("No such document!");
    }
  };
  const kyc = docdata.isKycComplete && docdata.isKycComplete;

  useEffect(() => {
    if (currentUser != null) {
      //  props.application.setLoading(false)
      getData();
      // props.application.setLoading(false)
    }
  }, [currentUser]);

  return (
    <React.Fragment>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          {!kyc && (
            <h6>
              <a
                className="btn btn-primary top-nav  floating "
                data-bs-toggle="modal"
                href="#exampleModalToggle"
                role="button"
              >
                Complete your KYC{" "}
                <i className="fa-solid fa-angle-right pl-5"></i>
              </a>
            </h6>
          )}

          <li className="nav-item">
            <Link
              className={`nav-link  ${
                splitLocation[1] === "dashboard" ? "collapsed" : ""
              } `}
              to={Routes.DASHBOARD}
            >
              <i className="fa-solid fa-dashboard" />
              <span>Dashboard</span>
            </Link>
          </li>
          {/* End Dashboard Nav */}
          {/* End Components Nav */}
          <li className="nav-item">
            <Link
              className={`nav-link  ${
                splitLocation[1] === "profile" ? "collapsed" : ""
              } `}
              to={Routes.PROFILE}
            >
              <i className="fa-regular fa-user" />
              <span>Profile</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link  ${
                splitLocation[1] === "payments" ? "collapsed" : ""
              } `}
              to={Routes.PAYMENTS}
            >
              <i className="fa-solid fa-exchange" />
              <span>Transactions</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link  ${
                splitLocation[1] === "api" ? "collapsed" : ""
              } `}
              to={Routes.API}
            >
              <i className="fa-solid fa-file"></i>
              <span>Api Keys</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link  ${
                splitLocation[1] === "subscription" ? "collapsed" : ""
              } `}
              to={Routes.SUBSCRIPTIONS}
            >
              <i className="fa-solid fa fa-refresh" />
              <span>Subscription</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link  ${
                splitLocation[1] === "offers" ? "collapsed" : ""
              } `}
              to={Routes.OFFERS}
            >
              <i className="fa-solid fa fa-tag" />
              <span>Offers</span>
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link className="nav-link" to="#">
              <i className="fa-solid fa-triangle-exclamation" />
              <span>Error 404</span>
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link className="nav-link" to="#">
              <i className="fa-solid fa-briefcase" />
              <span>Blank</span>
            </Link>
          </li> */}
          <li className="nav-item">
            <Link
              className="nav-link"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                props.auth.logout();
                history.push("/");
                console.log("Logged out");
              }}
            >
              <i className="fa-solid fa-arrow-right-to-bracket" />

              <span>LogOut</span>
            </Link>
          </li>
        </ul>
      </aside>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1"
      >
        {<KycModel />}
      </div>
    </React.Fragment>
  );
};

export default injectModels(["auth",'application'])(SideBar);
