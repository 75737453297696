import React from 'react';

const Banner = () => {
    return (
        <section className="slider">
        <div className="container">
            <div className="row">
                <div className="col-md-6 d-flex align-items-center">
                    <div className="slider-content">
                        <h1>Introduction to <span> DOTPAY </span></h1>

                        <p className="pr-4">
                        Your Virtuous Gateway to the Cryptocurrency Payments.
                        </p>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="slider-content">
                        <img src="/assets/img/bitcoin-6H5RSB8.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
};

export default Banner;