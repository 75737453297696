import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../constants";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [error, setError] = useState("");
  const { forgotPassword } = useAuth();

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      //validations for email
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Enter a valid Email ");
    } else {
      setEmailError("");
    }
    setEmail(val.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a Valid Email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!valid) {
      return;
    } else {
      setError("");

      /* forgot  password logic */

      forgotPassword(email)
        .then(() => {
          e.target.reset();
          toast.success(
            `An email is sent to ${email} for password reset instructions.`,
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        })
        .catch((error) => {
          e.target.reset();
          toast.error(error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <div>
      <section className="contact-us3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-logo">
                <Link to="/">
                  {" "}
                  <img src="/assets/img/signup-logo.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container contact-bg-color login-content">
          <div className="row  ">
            <div className="col-md-6 ">
              <div className="img-select1">
                <img src="/assets/img/forgot.png" alt="" />
              </div>
            </div>
            <div className="col-md-6 hello">
              <div
                className="contact-form-content1"
                style={{ background: "none", boxShadow: "none" }}
              >
                <form className="mt-1" onSubmit={handleSubmit}>
                  <div className="signup-logo1">
                    <Link to={Routes.LOGIN}>
                      <i class="fa-solid fa-arrow-left"></i>
                    </Link>

                    <h2>ForgotPassword</h2>
                  </div>

                  <div className="col-md-12 pl-0">
                    <div className="searchformfld">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        onChange={handleEmailChange}
                      />
                      <label>
                        Email Address<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      {emailError && (
                        <span className="error">{emailError}</span>
                      )}
                    </div>
                  </div>
                  {error && (
                    <div className="col-xs-12 col-md-12 col-lg-12">
                      <div className="alert alert-danger">{error}</div>
                    </div>
                  )}
                  <div className="submit-button">
                    <button type="submit" className="btn btn-primary">
                      Submit &nbsp;
                    </button>
                  </div>
                  {/* <div className="user-alreay">
                  <Link to={Routes.LOGIN}>Login</Link>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;

/* file created by sourav mishra  on dated-09/06/22 */
