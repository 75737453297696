import { signInWithEmailAndPassword ,createUserWithEmailAndPassword,signOut} from "firebase/auth";
import {LOGIN_SUCCESS, LOGIN_FAILED,LOGOUT } from './constants';
import { auth } from "../../../components/Firebase/config";
import {JWT} from "../../../shared";
import { async } from "@firebase/util";

export const login = (email, password) => async(dispatch, getState) => {
        try{
            const response = await signInWithEmailAndPassword(auth, email, password);
            console.log("response is here", response)
            if(response){
               dispatch({type:LOGIN_SUCCESS, payload:response.user.accessToken});
               JWT.setJwt(response.user.accessToken);
            console.log(response,"inside response")
               return response;
            } else {
               dispatch({type:LOGIN_FAILED, payload:null});
               return Promise.reject(new Error(response.message)); 
            }
        } catch(error){
            dispatch({type:LOGIN_FAILED, payload:null});
            return Promise.reject(error);
        }
}

export const logout = () =>async  (dispatch, getState) => {
    signOut(auth)
   JWT.removeJWT();
   localStorage.setItem("wallet", "");
   localStorage.setItem("wallet_type", ""); 
   dispatch({type:LOGOUT});
}

export const register = (email, password, username, name, image) => async(dispatch,getState) =>{
   try{
      const response = await createUserWithEmailAndPassword(auth,email, password, username, name, image)
      console.log(response,"register response")
      if(response){
         return response;
      }else {
         return Promise.reject(new Error(response.message)); 
      }
     
   }catch(error){
      return Promise.reject(error);
   }
}