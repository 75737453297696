import React from 'react'

const Footer = () => {
  return (
        <footer id="footer" className="dash-footer">
        <div className="copyright">
            © Copyright <strong><span>DotPay</span></strong>. All Rights Reserved
        </div>
        </footer>
  )
}

export default Footer