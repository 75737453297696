import React from 'react';

const NotFound = () => {
    return (
        <main>
        <section className="author-area">
            <div className="container">
            <div className="dots"><h2 className="mt-5 mb-4">404<span>Not found </span></h2></div>
            </div>
         </section>  
         </main> 
    );
};

export default NotFound;