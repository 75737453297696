import React,{useEffect} from "react";

const Mission = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  return (
    <React.Fragment>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content  d-flex align-items-center">
              <h2>Our Mission</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="our-values">
        <div className="container">
          <div className="our-mission">
            <h6>Our mission is to increase economic freedom in the world</h6>
            <p>
              Everybody merits admittance to monetary administrations that can
              assist with engaging them to make a superior life for themselves
              as well as their families. Assuming the world economy ran on a
              typical arrangement of norms that couldn't be controlled by any
              organization or country, the world would be an all the more fair
              and free spot, and human advancement would speed up.
            </p>
          </div>
          <div className="our-cultre">
            <h2>Our culture</h2>
          </div>
          <div className="row justify-content-center">
            <div className=" col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
                
                <div className="v-card-icon">
                <i className="fa-solid fa-database"></i>
                </div>
                <h4>Clear correspondence</h4>
                <p>
                  We are immediate and brief. We share data effectively, further
                  developing coordinated effort and efficiency. We practice
                  undivided attention.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
                <a href="#">
                  
                  <div className="v-card-icon">
                  <i className="fa-solid fa-chalkboard"></i>
                  </div>
                  <h4>Persistent learning</h4>
                  <p>
                    We're modest, and esteem learning over being correct. We
                    embrace conveying and getting genuine criticism, and
                    consider each misfortune to be an amazing chance to learn.
                  </p>
                </a>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
                
                <div className="v-card-icon">
                <i className="fa-solid fa-chart-line"></i>
                </div>
                <h4>Productive execution</h4>
                <p>
                  We have a predisposition for activity. We complete top-notch
                  work rapidly. We center around the 20% of work that will get
                  us 80% of the effect.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
                
                <div className="v-card-icon">
              <i className="fa-solid fa-rotate" />

                </div>
                <h4>Top-notch ability</h4>
                <p>
                  We go to phenomenal lengths to have excellent individuals in
                  each seat. We find out if every competitor will raise the
                  typical in the group before making a proposition. We cast a
                  wide net, to draw in up-and-comers from each foundation,
                  zeroing in on both expertise and culture arrangement. We
                  effectively mentor and create. Unexceptional execution gets a
                  liberal severance bundle.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
                
                <div className="v-card-icon">
                <i className="fa-solid fa-rotate" />

                </div>
                <h4>Repeatable advancement</h4>
                <p>
                  We are makers, using advancement to chip away at the world,
                  persistently shipping contemplations versus just discussing
                  them. We understand that associations ought to endlessly
                  rethink themselves to avoid stagnation. We have a high ability
                  to bear dissatisfaction, zeroing in significantly on
                  experience bets that are clumsily forceful.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
               
                <div className="v-card-icon">
                <i className="fa-solid fa-user-group" />

                </div>
                <h4> Victorious squad</h4>
                <p>
                  We are a triumphant group, and have elevated requirements for
                  execution and conveying results. We're more grounded together
                  so we decide to zero in on what joins us, and not on which
                  isolates us. We have an extreme work culture and are routinely
                  pushed out of our usual ranges of familiarity. We extremely
                  treat rest, to further develop efficiency over the long haul.
                  Coinbase ought to ceaselessly procure representatives'
                  responsibility and anticipates that workers should acquire
                  their seats at the organization.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
               
                <div className="v-card-icon">
               <i className="fa-solid fa-trophy" />

                </div>
                <h4> Carry on like a proprietor</h4>
                <p>
                  We assume a sense of ownership in accomplishing the mission.
                  We look to work on all parts of our organization even in
                  manners that are not expressly an aspect of our
                  responsibilities.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
                
                <div className="v-card-icon">
                <i className="fa-solid fa-bullseye" />

                </div>
                <h4> Aim Focussed</h4>
                <p>
                  Our main goal is aggressive and significant. We don't
                  participate in friendly or political activism that is
                  irrelevant to our central goal while at work. We look to make
                  the work environment a shelter from division, so we can keep
                  fixed on gaining ground toward the mission.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
               
                <div className="v-card-icon">
                <i className="fa-solid fa-user-tie"></i>
                </div>
                <h4> Customer-centered</h4>
                <p>
                  We are profoundly centered around tackling our clients'
                  concerns with innovation, by empowering them to get, store and
                  use crypto. We endeavor to be the simplest to utilize, most
                  trusted, and most secure stage. In each choice we make, we
                  inquire, "How does this make more incentive for our clients?"
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-4 mb-4 v-card-gap">
              <div className="v-card">
               
                <div className="v-card-icon">
                <i className="fa-solid fa-magnifying-glass-chart"></i>
                </div>
                <h4> Constructive energy</h4>
                <p>
                  We are hopeful about the not entirely set in stone to arrival.
                  We co-make arrangements as opposed to picking fault and
                  analysis. We make snapshots of play at work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Mission;
