import * as CONSTANTS from "./constants";
import Web3 from "web3";
import { ethers } from "ethers";
import * as CONTRACT from "../../../contracts";
import getWalletProvider from "../../../utils/getWalletProvider";

const DotBankAddress = process.env.REACT_APP_DOTPAY_ADDRESS;

const web3Object = new Web3(
  new Web3.providers.HttpProvider(`${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`)
);

export const setLoading =
  (val, message = "") =>
  async (dispatch, getState) => {
    try {
      if (val === true) {
        dispatch({ type: CONSTANTS.SET_LOADING_TRUE, payload: message });
      } else {
        dispatch({ type: CONSTANTS.SET_LOADING_FALSE, payload: "" });
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

export const getMerchantTransactions =
  (merchantContractAddress) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getMerchantTransactions(`${merchantContractAddress}`)
        .call();

      return transaction;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

export const getMerchantContractAddress =
  (userId) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getMerchantAddress(`${userId}`)
        .call();

      return transaction;
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

export const getMerchantBnbBalance =
  (merchantContractAddress) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getMerchantBNBBalance(`${merchantContractAddress}`)
        .call();

      return transaction;
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

export const getCustomTokenBalance =
  (merchantContractAddress, tokenAddress) => async (dispatch, getState) => {
    try {
      const dotPayContract = new web3Object.eth.Contract(
        CONTRACT.DotPayContract.abi,
        DotBankAddress
      );
      const transaction = await dotPayContract.methods
        .getMerchantTokenBalance(merchantContractAddress, tokenAddress)
        .call();

      return transaction;
    } catch (error) {
      console.log("error", error);

      return null;
    }
  };

export const getTokenName = (tokenAddress) => async (dispatch, getState) => {
  try {
    const dotPayContract = new web3Object.eth.Contract(
      CONTRACT.TokenContract.abi,
      tokenAddress
    );
    const transaction = await dotPayContract.methods.name().call();
    const transaction2 = await dotPayContract.methods.symbol().call();

    return transaction + " (" + transaction2 + ")";
  } catch (error) {
    console.log("error", error);

    return null;
  }
};

export const WithdrawToken =
  (merchantContractAddress, tokenAddress, tokenAmount) =>
  async (dispatch, getState) => {
    debugger;
    try {
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();
        console.log(signer, "signer");
        const dotPayContract = new ethers.Contract(
          merchantContractAddress,
          CONTRACT.MerchantContract.abi,
          signer
        );

        // Asking if metamask is already present or not

        // res[0] for fetching a first wallet
        let walletAddress1 = localStorage.getItem("wallet");

        let transaction = await dotPayContract.transferToken(
          tokenAddress,
          tokenAmount,
          walletAddress1
        );
        console.log(transaction, "transaction");
        return true;

        return false;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };
// export const WithdrawBNB = (merchantContractAddress,tokenAmount) => async (dispatch, getState) => {
//   try {

//     const provider = await getWalletProvider();
//     if (!provider) {
//       throw new Error("Only metamask is supported");
//     } else {
//       const signer = provider.getSigner();
//       console.log(signer, "signer");
//       const dotPayContract = new ethers.Contract(
//         merchantContractAddress,
//         CONTRACT.MerchantContract.abi,
//         signer
//       );
//       const dotPayContractA = new web3Object.eth.Contract(
//         CONTRACT.MerchantContract.abi,
//         merchantContractAddress
//       );

//       let t = await dotPayContractA.methods.getMerchantOwner().call();
//       console.log(t, "transacthguifgiurfgiaeygfileryhgion");
//       tokenAmount=tokenAmount*10e18;
//       let transaction = await dotPayContract.withdrawBNB( tokenAmount,t);
//       console.log(transaction, "transaction");

//       return true;
//     }
//   } catch (err) {
//     console.log(err);
//     return Promise.reject(err);
//   }
// };

export const WithdrawBNB =
  (merchantContractAddress, tokenAmount) => async (dispatch, getState) => {
    try {
      const provider = await getWalletProvider();
      if (!provider) {
        throw new Error("Only metamask is supported");
      } else {
        const signer = provider.getSigner();
        console.log(signer, "signer");
        const dotPayContract = new ethers.Contract(
          merchantContractAddress,
          CONTRACT.MerchantContract.abi,
          signer
        );
        const dotPayContractA = new web3Object.eth.Contract(
          CONTRACT.MerchantContract.abi,
          merchantContractAddress
        );

        let token = await dotPayContractA.methods.getMerchantOwner().call();

        let transaction = await dotPayContract.withdrawBNB(tokenAmount, token);

        return true;
      }
    } catch (err) {
      console.log(err);
      return Promise.reject(err);
    }
  };
