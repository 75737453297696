import React, { useEffect, useState } from "react";
import { injectModels } from "../../redux/injectModels";
import { Button, Modal } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase/config";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const TransactionPage = (props) => {
  const [transactionData, setTransactionData] = useState("");
  const [balance, setBalance] = useState("");
  const [error, setError] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState("");
  const { currentUser } = useAuth();
  const [showModal, setShow] = useState(false);
  const [docdata, setdocData] = useState([]);
  const [customTokenBalance, setCustomTokenBalance] = useState("");
  const uid = currentUser && currentUser.uid;

  const walletType = localStorage.getItem("wallet_type");
  const walletAddress = localStorage.getItem("wallet");

  const getData = async () => {
    const docRef = doc(db, "Kyc", uid);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      setdocData(data);
    } else {
      console.log("No such document!");
    }
  };

  const getContractAddress = async () => {
    const userId = uid && uid;
    try {
      if (userId) {
        props.application.setLoading(true);
        const address = await props.application.getMerchantContractAddress(
          userId
        );
        props.application.setLoading(false);
        return address;
        // setContractAddreess(address);
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTokenBalance = async (e) => {
    e.preventDefault();
    let valid = true;
    if (tokenAddress === "") {
      setError("Form is not Valid");
      valid = false;
    } else {
      setError("");
    }

    if (!valid) {
      setError("Form is not Valid");
      return;
    } else {
      setError("");
      try {
        props.application.setLoading(true);
        const address = await getContractAddress();

        const transaction = await props.application.getCustomTokenBalance(
          address,
          tokenAddress
        );
        const tokenTransaction = await props.application.getTokenName(
          tokenAddress
        );
        const tokenBalance = transaction;
        setCustomTokenBalance(transaction);
        setTokenBalance(tokenBalance / 1e18 + " " + tokenTransaction);

        props.application.setLoading(false);
      } catch (err) {
        props.application.setLoading(false);
        console.log(err);
        return Promise.reject(err);
      }
    }
  };

  const handleAddress = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("You need to Token Address");
    } else {
      setError("");
    }
    setTokenAddress(val.trim());
  };

  const getBnbBalance = async () => {
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        if (address === "0x0000000000000000000000000000000000000000") {
          setBalance("0");
          props.application.setLoading(false);
        } else {
          props.application.setLoading(true);
          const transaction = await props.application.getMerchantBnbBalance(
            address
          );
          // const bnb = transaction / 10e17;
          props.application.setLoading(false);
          setBalance(transaction);
        }
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const handleTokenWithdraw = async () => {
    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (
      (localStorage.getItem("wallet")).toUpperCase() !==
      (docdata.WalletAddress).toUpperCase()
    ) {
      toast.error("You Are Not Connected With owner Wallet Address!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (customTokenBalance === "0") {
      toast.error("Insufficent amount to withdraw", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        const transaction = await props.application.WithdrawToken(
          address,
          tokenAddress,
          customTokenBalance
        );

        props.application.setLoading(false);
      }
    } catch (err) {
      if (err.code === "INVALID_ARGUMENT") {
        toast.error("First get the balance", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (err.code === "ACTION_REJECTED") {
        toast.error("User denied the request", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const handleWithdrawBNB = async () => {
    if (!walletType && !walletAddress) {
      toast.error("Wallet not connected!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (
      (localStorage.getItem("wallet")).toUpperCase() !==
      (docdata.WalletAddress).toUpperCase()
    ) {
      toast.error("You Are Not Connected With owner Wallet Address!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (balance === "0") {
      toast.error("Insufficent amount to withdraw", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        const transaction = await props.application.WithdrawBNB(
          address,
          balance
        );
        props.application.setLoading(false);
      }
    } catch (err) {
      if (err.code === 4001) {
        toast.error("User denied the request", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (err.code === "ACTION_REJECTED") {
        toast.error("User denied the request", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const getTrasactions = async () => {
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        const transaction = await props.application.getMerchantTransactions(
          address,
          balance
        );
        props.application.setLoading(false);
        setTransactionData(transaction);
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    getTrasactions();
    getBnbBalance();
    getData();
  }, [currentUser, balance]);

  return (
    <React.Fragment>
      <section>
        <div className="bnb-btn">
          <div className="total-bnb">
            <h6>
              <span>Total BNB :</span>
              {balance / 10e17}
            </h6>
          </div>

          <button onClick={handleShow}>Check Token Balance</button>
        </div>
        <div className="Withdraw-bnb">
          <button onClick={handleWithdrawBNB} className="token">
            Withdraw BNB
          </button>
        </div>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Check Token Balance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Please Enter Your Token Address
            <form>
              <div className="searchformfld">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Address"
                  onChange={handleAddress}
                />
                <Button onClick={getTokenBalance}>Get Balance</Button>
              </div>
            </form>
            <div>Token Balance:{tokenBalance}</div>
            <Button onClick={handleTokenWithdraw}>Withdraw</Button>
          </Modal.Body>
          {error && (
            <div className="col-xs-12 col-md-12 col-lg-12">
              <div className="alert alert-danger">{error}</div>
            </div>
          )}
        </Modal>
      </section>
      <div className="table-responsive">
        <table id="example1" className="table table-bordered table-striped">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Merchant UId</th>
              <th>Amount</th>
              <th>Walllet Address</th>
              <th>Contract Address</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {transactionData && transactionData.length > 0 ? (
              <React.Fragment>
                {transactionData.map((item, idx) => {
                  const timeStamp = item.timestamp;
                  const theDate = new Date(timeStamp * 1000);
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{uid && uid}</td>
                      <td>{item.amount}</td>
                      <td>{item.from}</td>
                      <td>{item.to}</td>
                      <td>{theDate.toGMTString()}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ) : (
              <tr>
                <td colSpan="10 mt-2">No Results found For The Transaction!</td>
              </tr>
            )}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

export default injectModels(["auth", "application"])(TransactionPage);
