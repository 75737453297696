import React, {useEffect, useState} from 'react'
import EmailVerified from './KycForm/EmailVerified'
import PhoneVerified from './KycForm/PhoneVerified'
import KycForm1 from './KycForm/KycForm1'
import KycForm2 from './KycForm/KycForm2'
import KycForm3 from './KycForm/KycForm3'


const Model = () => {
 const [stat1,  setState1] = useState(false);
 const [stat2,  setState2] = useState(false);
 const [stat3,  setState3] = useState(false);
 const [stat4,  setState4] = useState(false);
 const [stat5,  setState5] = useState(false);

 useEffect(() => {
  setState1(true)
}, []);

 const handleClick = (event) => {
//  console.log(event.currentTarget.id);
//  console.log("data");
 setState1(false);
 setState2(false);
 setState3(false);
 setState4(false);
 setState5(false);
 switch (event.currentTarget.id) {

  case 'my-btn1':
    return(
      setState1(true)
    );

    case 'my-btn2':
      return(
        setState2(true)
      );
 
      case 'my-btn3':
        return(
          setState3(true)
        );

        case 'my-btn4':
        return(
          setState4(true)
        );

        case 'my-btn5':
        return(
          setState5(true)
        );

        default:
         
 }
}; 



  return (
    <>
  <div className="modal-dialog modal-xl  modal-dialog-centered">
          <div className="modal-content">
            <div className="row no-gutters">
              <div className="col-md-4">
                <div className="kyc-content-data">
                  <h2>KYC Form</h2>
                  <p>Complete and submit the form to accept payments.</p>

                <ul className="nav nav-tabs">
                  <li >
                      <a href="#contact" data-toggle="tab" className="test active" id="my-btn1" onClick={handleClick}>
                        Kyc Steps
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                      </a>
                    </li>
                    {/* <li >
                      <a href="#email" data-toggle="tab" className="test"  id="my-btn2"  onClick={handleClick} >
                       Email Verification
                       <i className="fa-solid fa-chevron-right"></i>{" "}
                      </a>
                    </li>
                    <li >
                      <a href="#phone" data-toggle="tab" className="test" id="my-btn3"  onClick={handleClick}>
                       Phone Verfication
                        <i className="fa-solid fa-chevron-right"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#buisness" data-toggle="tab" className="test" id="my-btn4"  onClick={handleClick}>
                        Business Overview<i className="fa-solid fa-chevron-right"></i>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#details" data-toggle="tab" className="test" id="my-btn5"  onClick={handleClick} >
                        Business Details<i className="fa-solid fa-chevron-right"></i>{" "}
                      </a>
                    </li> */}
                  </ul> 
                                    
                </div>
              </div>
              <div className="col-md-8">
                <div className="kyc-content-data-div">
                  <div className="modal-header3">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane active" id="contact">
                      
                      {stat1  &&
                       <div className="">
                        <KycForm1 />
                      </div>
                      } 
                    </div>
                    <div className="tab-pane " id="email">
                      {stat2 && 
                      <div className="">
                        <EmailVerified />
                      </div>
                      }
                    </div>
                    {/* <div className="tab-pane " id="phone">
                      {stat3 && 
                      <div className="">
                      <PhoneVerified />
                    </div>
                      }
                      
                    </div> */}

                    <div className="tab-pane" id="buisness">
                      {stat4 && 
                       <div className="">
                       <KycForm2 />
                       </div>}
                     
                    </div>

                    <div className="tab-pane" id="details">
                      {stat5 && 
                        <div className="">
                        <KycForm3 />
                        </div>
                      }
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Model