import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useState } from "react";
import { CropImage } from "../Cropper";
import { Routes } from "../../constants";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";

//Firebase
import { db } from "../Firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { injectModels } from "../../redux/injectModels";

import { Icon } from 'react-icons-kit'
import {eye} from 'react-icons-kit/feather/eye'
import {eyeOff} from 'react-icons-kit/feather/eyeOff'

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const phoneRegex =/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
const phoneRegex = RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/
);
const passRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

const userNameRegex = /^[A-Za-z][A-Za-z0-9_]{6,29}$/;
const nameRegex = /^[a-zA-Z ]{2,30}$/;

const Signup = (props) => {
  const storage = getStorage();

  const { register } = useAuth();

  //usestate constants
  const history = useHistory();
  const [image, setImage] = useState(null);
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [usernameError, setUserNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passError, setPassError] = useState("");
  const [rpassword, setRpassword] = useState("");
  const [rpassError, setRpassError] = useState("");
  const [imageError, setImageError] = useState();
  const [error, setError] = useState("");
  const [avtarModelOpen, setAvtarModelOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [type, setType]=useState('password');
  const [icon, setIcon]=useState(eyeOff);

  const handelAvtarFile = (value) => {
    if (
       name === "" ||
      email === "" ||
      username === "" ||
      phone === "" ||
      password === "" ||
      rpassword === ""
    ) {
      // toast.error("Please fill your details first", {
      //   position: toast.POSITION.BOTTOM_CENTER,
      // });
      setError("Please fill your Personal details first")
    } else {
      if (value) {
        //handling image file
        console.log(value, "value");
        setImage(value);
        setError("")
        setAvtarModelOpen(true);
      }
    }
  };

    
  const handleToggle=()=>{    
    if(type==='password'){
      setIcon(eye);      
      setType('text');
    }
    else{
      setIcon(eyeOff);     
      setType('password');
    }
  }
  const getCroppedImageAvtar = async (blob) => {
    if (blob) {
      try {
        setImageLoading(true);
        const imageRef = ref(
          storage,
          "merchants/" + email + "/profile" + Date.now()
        );
        const snapshot = await uploadBytes(imageRef, blob);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log(downloadURL, "image");
        setAvatarUrl(downloadURL);
        setImageLoading(false);
      } catch (error) {
        console.log(error);
        setImageLoading(false);
      }
    }
  };

  const handleNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setNameError("Your Name is required"); //validations for name field
    } else if (!nameRegex.test(val)) {
      setNameError("Enter a valid name");
    } else {
      setNameError("");
    }
    setName(val.trim());
  };

  const handleUserNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setUserNameError("User Name is required"); //validations for name field
    } else if (!userNameRegex.test(val)) {
      setUserNameError("Enter a unique Username at least 6 character");
    } else {
      setUserNameError("");
    }
    setUserName(val.trim());
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (e.target.value.length === 17) return false;
    if (val.trim() === "") {
      setPhoneError("Phone Number is required"); //validations for phone
    } else if (!phoneRegex.test(val)) {
      setPhoneError("Enter a valid Phone Number ");
    } else {
      setPhoneError("");
    }
    setPhone(val.trim());
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      //validations for email
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Enter a valid Email ");
    } else {
      setEmailError("");
    }
    setEmail(val.trim());
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setPassError("password is required");
    } else if (!passRegex.test(val)) {
      //password validation

      setPassError(
        "Use a mix of alphabetical and numeric characters with at least one upper case"
      );
    } else {
      setPassError("");
    }
    setPassword(val);
  };

  const handleRpasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      //repeat password validation
      setRpassError("Confirm Password is required");
    } else if (val !== password) {
      setRpassError("Password does not Match");
    } else {
      setRpassError("");
    }
    setRpassword(val);
  };

  const handleSubmit = async (e) => {
    //  props.application.setLoading(true)
    e.preventDefault();
    let valid = true;
    if (image == null) {
      setImageError("Profile Image is required");
      valid = false;
    } else {
      setImageError("");
    }
    if (name === "") {
      setNameError("Your Name is required");
      valid = false;
    } else {
      //submit response handle
      setNameError("");
    }
    if (username === "") {
      setUserNameError("User Name is required");
      valid = false;
    } else {
      setUserNameError("");
    }
    if (phone === "") {
      setPhoneError("Phone Number is required");
      valid = false;
    } else {
      setPhoneError("");
    }
    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a Valid Email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPassError("Password is required");
      valid = false;
    } else {
      setPassError("");
    }
    if (rpassword !== password) {
      setRpassError("Password does not Match");
      valid = false;
    } else {
      setRpassError("");
    }

    if (!valid) {
      return;
    } else {
      setError("");
      props.application.setLoading(true);
      register(email, password, username, name, image)
        .then((userCredential) => {
          props.application.setLoading(true); // Signed in
          const user = userCredential.user;
          setDoc(doc(db, "Merchants", user.uid), {
            username: username,
            name: name,
            email: email,
            phone: phone,
            image: avatarUrl,
            isProfileVerified: false,
            isKycComplete: false,
            uid: user.uid,
          })
            .then(() => {
              props.application.setLoading(false);
              toast.success("Register successfully!", {
                position: toast.POSITION.TOP_CENTER,
              });
              history.push("./login");
            })
            .catch((e) => {
              props.application.setLoading(false);
              const errorMessage = e.code.split("auth/")[1];
              setError(errorMessage);
            });
        })
        .catch((e) => {
          props.application.setLoading(false);
          const errorMessage = e.code.split("auth/")[1];
          setError(errorMessage);
        });
    }
  };
  const mystyle = {
    color: "#fff",
    paddingLeft: "0px",
  };
  // <!-- return statement -->
  <style></style>;

  return (
    <React.Fragment>
      <section className="contact-us3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-logo">
                <Link to="/">
                  {" "}
                  <img src="/assets/img/signup-logo.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="signup-logo1">
                <h2 style={mystyle}>Merchant Signup</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container contact-bg-color">
          <div className="row  ">
            <div className="col-md-6 ">
              <div className="img-select">
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <div className=" form-group">
                      <img
                        className="signup-image"
                        src={
                          imageLoading
                            ? "/assets/img/loader.svg"
                            : avatarUrl
                            ? avatarUrl
                            : "/assets/img/bg-form.jpg"
                        }
                        alt=""
                      />
                      <div
                        className="custom-file mt-2 upload"
                        style={{ zIndex: 0 }}
                      >
                        <input
                          type="file"
                          name="myImage"
                          className="custom-file-input"
                          id="inputGroupFile01211"
                          required
                          onChange={(e) => handelAvtarFile(e.target.files[0])}
                        />

                        <label
                          className="custom-file-label"
                          htmlFor="inputGroupFile01"
                        >
                          {avatarUrl ? (
                            image.name.slice(0, 20)
                          ) : (
                            <span className="error">{imageError}</span>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <div
                className="contact-form-content signup-content"
                style={{ background: "none", boxShadow: "none" }}
              >
                <h3> Personal Details</h3>
                <form className="mt-1" onSubmit={handleSubmit}>
                  <div className="col-md-12 pl-0 pr-0">
                    <div className="searchformfld">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        onChange={handleUserNameChange}
                      />
                      <label>
                        User Name<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      {usernameError && (
                        <span className="error">{usernameError}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <div className="searchformfld">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        onChange={handleNameChange}
                      />
                      <label>
                        Your Name<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      {nameError && <span className="error">{nameError}</span>}
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <div className="searchformfld">
                      <input
                        type="number"
                        className="form-control"
                        placeholder=" "
                        onChange={handlePhoneChange}
                        value={phone}
                      />
                      <label>
                        Phone Number<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      {phoneError && (
                        <span className="error">{phoneError}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <div className="searchformfld">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        onChange={handleEmailChange}
                      />
                      <label>
                        Email Address<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      {emailError && (
                        <span className="error">{emailError}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <div className="searchformfld">
                      <input
                       type={type}
                        className="form-control"
                        placeholder=" "
                        onChange={handlePasswordChange}
                      />
                      <label>
                        Password<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <div className="handle">
                      <span onClick={handleToggle}><Icon icon={icon}/></span> </div>
                     
                      {passError && <span className="error">{passError}</span>}
               
                    </div>
                  </div>
                  <div className="col-md-12 pl-0 pr-0">
                    <div className="searchformfld">
                      <input
                        type="password"
                        className="form-control"
                        placeholder=" "
                        onChange={handleRpasswordChange}
                      />
                      <label>
                        Confirm Password
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      {rpassError && (
                        <span className="error">{rpassError}</span>
                      )}
                    </div>
                  </div>
                  {error && (
                    <div className="col-xs-12 col-md-12 col-lg-12">
                      <div className="alert alert-danger">{error}</div>
                    </div>
                  )}
                  <div className="submit-button">
                    <button
                      type="submit"
                      className="btn btn-primary  submit-color"
                    >
                      Submit &nbsp;
                      {/* {loader ? <MyLoader /> : ""} */}
                      {/* <i className="fa-solid fa-right-long" />{" "} */}
                    </button>
                  </div>
                  <div className="user-alreay">
                    <span className="text-div">already a user?</span>{" "}&nbsp;
                    <Link to={Routes.LOGIN}>Login</Link>
                  </div>

                  {/* <button
                    onClick={() =>
                      signInWithGoogle()
                        .then((user) => console.log(user))
                        .catch((e) => console.log(e.message))
                    }
                  >
                    Sign in with Google
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CropImage
        isOpen={avtarModelOpen}
        file={image}
        getCroppedImage={getCroppedImageAvtar}
        aspectRatio={1}
      />
    </React.Fragment>
  );
};

export default injectModels(["auth", "application"])(Signup);

/* file created by sourav mishra */
