import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../constants";
import { routes } from "../constants/routes";
import { JWT } from "../shared";

const Header = () => {
  
  const handleDrop = (e) => {
    console.log("click");
    e.preventDefault();

    const list = document.getElementById("navbarTogglerDemo02").classList;
    if (list.contains("show")) {
      list.remove("show");
    } else {
      list.add("show");
    }
  };
  const isValidToken = JWT.isValidToken();
  return (
    <header className="header">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="logo">
            <Link className="navbar-brand" to="/">
              {" "}
              <img src="/assets/img/logo.png" alt="" />{" "}
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul className="navbar-nav mt-2 mt-lg-0 ml-auto">
              <li className="nav-item " onClick={handleDrop}>
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item" onClick={handleDrop}>
                <Link className="nav-link" to={Routes.EXPLORE}>
                  {" "}
                  Explore
                </Link>
              </li>
              <li>
                {/*
                         note:commented on 3/6/2022 by sourav mishra
                         
                         <div className="dropdown">
                        <button className="dropbtn">Merchant</button>
                        <div className="dropdown-content">
                            <a href={Routes.SIGNUPONE}>Sign Up</a>
                            <a href={Routes.LOGIN}>Merchant Login</a>
                            <a href="true">Profile</a>
                        </div>
                        </div> */}
              </li>

              <li className="nav-item" onClick={handleDrop}>
                <Link to={Routes.FAQ} className="nav-link">
                  {" "}
                  FAQs
                </Link>
              </li>

              <li className="nav-item" onClick={handleDrop}>
                <Link to={routes.CONTACT} className="nav-link">
                  {" "}
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="toggle-content">
            {!isValidToken && (
              <div className="token-button">
                <Link className="nav-link contact-button" to={Routes.SIGNUPONE}>
                  {" "}
                  Sign Up{" "}
                  <span className="user-icon">
                    <i className="fa-solid fa-user-plus"></i>
                  </span>
                </Link>
                <Link className="nav-link contact-button" to={Routes.LOGIN}>
                  {" "}
                  Sign In{" "}
                  <span className="user-icon">
                    <i className="fa-sharp fa-solid fa-right-to-bracket"></i>
                  </span>
                </Link>
              </div>
            )}
            {isValidToken && (
              <div className="token-button">
                <Link className="nav-link contact-button" to={Routes.DASHBOARD}>
                  {" "}
                  <span className="user-icon">
                    <i className="fa-solid fa-gauge"></i>
                  </span>
                  Dashboard
                </Link>
              </div>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
