import React from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useState, useEffect } from "react";
import KycForm3 from "./KycForm3";
import { toast } from "react-toastify";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import { injectModels } from "../../../redux/injectModels";

const KycForm2 = (props) => {
  const { currentUser } = useAuth();
  const [docdata, setdocData] = useState([]);
  const [activeForm, setactiveForm] = useState(false);
  const [buisnessType, setbuisnessType] = useState("");
  const [buisnessCategory, setBuisnessCategory] = useState("");
  const [description, setDescription] = useState("");
  const [buisnessTypeError, setbuisnessTypeError] = useState("");
  const [buisnessCategoryError, setbuisnessCategoryError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [urlError, setUrlError] = useState("");
  const [radio, setRadio] = useState(false);
  const [radioError, setRadioError] = useState("");


  const formActivation = () => {
    setactiveForm(true);
  };

  const userId = currentUser && currentUser.uid;

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      setdocData(data);
    } else {
      console.log("No such document!");
    }
  };

  useEffect(() => {
    if (currentUser != null) {
      getData();
    }
  }, [currentUser]);

  const handleBuisnesstypeChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setbuisnessTypeError("Please fill out this field");
    } else {
      setbuisnessTypeError("");
    }
    setbuisnessType(val.trim());
  };

  const handleBuisnessCategoryChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setbuisnessCategoryError("Please fill out this field");
    } else {
      setbuisnessCategoryError("");
    }
    setBuisnessCategory(val.trim());
  };

  const handleDescriptionChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setDescriptionError("Please fill out this field");
    } else {
      setDescriptionError("");
    }
    setDescription(val.trim());
  };

  const handleUrlChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setUrlError("Please fill out this field");
    } else {
      setUrlError("");
    }
    setUrl(val.trim());
  };

  const handleRadioButton = (e) => {
    if (radio === true) {
      setRadioError("Please choose any one of these");
    } else {
      // setOpen(true);
      setRadioError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let valid = true;

    if (buisnessType === "") {
      setbuisnessTypeError("Please fill out this field");
      valid = false;
    } else {
      setbuisnessTypeError("");
    }
    if (buisnessCategory === "") {
      setbuisnessCategoryError("Please fill out this field");
      valid = false;
    } else {
      setbuisnessCategoryError("");
    }
    if (description === "") {
      setDescriptionError("Please fill out this field");
      valid = false;
    } else {
      setDescriptionError("");
    }

    if (!url && radio === true) {
      setUrlError("Please Enter Url");
      valid = false;
    } else if (url && radio) {
      setRadioError(" ");
    } else {
      setRadioError("");
    }

    if (!valid) {
      toast.error("Form is not Valid", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.log("form is not valid");
      return;
    } else {
      
      props.application.setLoading(true)
      setDoc(doc(db, "Kyc", docdata.uid), {
        BuisnessType: buisnessType,
        buisnessCategory: buisnessCategory,
        Description: description,
        SiteUrl: url,
      })
        .then(() => {
          props.application.setLoading(false)
          toast.success("Submitted successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          formActivation();
        })
        .catch((e) => {
          props.application.setLoading(false)
          const errorMessage = e.code.split("auth")[1];
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <React.Fragment>
      <div className="form-content">
        {!activeForm ? (
          <React.Fragment>
            <section className="form-content">
              <div className="col-md-8">
                <div className="merchant-kyc-div Info">
                  <h2>BUSINESS OVERVIEW</h2>
                  <form>
                    <div className="form-group">
                      <label>
                        Business Type<span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={handleBuisnesstypeChange}
                      >
                        <option value>--Select--</option>
                        <option value="Proprietorship">Proprietorship</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Private Limited">Private Limited</option>
                        <option value="Public Limited">Public Limited</option>
                        <option value="LLP">LLP</option>
                        <option value="Trust">Trust</option>
                        <option value="Society">Society</option>
                        <option value="NGO">NGO</option>
                        <option value="HUF">HUF</option>
                        <option value="Not Yet Registered">
                          Not Yet Registered
                        </option>
                      </select>
                      {buisnessTypeError && (
                        <span className="error">{buisnessTypeError}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Business Category
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={handleBuisnessCategoryChange}
                      >
                        <option value>--Select--</option>
                        <option value="ecommerce">Ecommerce</option>
                        <option value="fashion_and_lifestyle">
                          Fashion and Lifestyle
                        </option>
                        <option value="food">Food and Beverage</option>
                        <option value="grocery">Grocery</option>
                        <option value="it_and_software">IT and Software</option>
                        <option value="healthcare">Healthcare</option>
                        <option value="services">Services</option>
                        <option value="web_development">
                          Web designing/Development
                        </option>
                        <option value="accounting">Accounting Services</option>
                        <option value="coupons">
                          Ad/Coupons/Deals Services
                        </option>
                        <option value="repair_and_cleaning">
                          Automotive Repair Shops
                        </option>
                        <option value="catering">Caterers</option>
                        <option value="charity">Charity</option>
                        <option value="computer_programming_data_processing">
                          Computer Programming/Data Processing
                        </option>
                        <option value="consulting_and_outsourcing">
                          Consulting/PR Services
                        </option>
                        <option value="Financial Services">
                          Financial Services
                        </option>
                        <option value="gaming">Gaming</option>
                        <option value="drop_shipping">
                          General Merchandise Stores
                        </option>
                        <option value="government">Government Bodies</option>
                        <option value="health_coaching">
                          Health and Beauty Spas
                        </option>
                        <option value="housing">Housing and Real Estate</option>
                        <option value="logistics">Logistics</option>
                        <option value="media_and_entertainment">
                          Media and Entertainment
                        </option>
                        <option value="not_for_profit">Not-For-Profit</option>
                        <option value="others">Others</option>
                        <option value="pass">Platform as a Service</option>
                        <option value="coworking">
                          Real Estate Agents/Rentals
                        </option>
                        <option value="saas">Software as a Service</option>
                        <option value="service_centre">Service Centre</option>
                        <option value="social">social</option>
                        <option value="telecommunication_service">
                          Pre/Post Paid/Telecom Services
                        </option>
                        <option value="telecommunication_service">
                          Pre/Post Paid/Telecom Services
                        </option>
                        <option value="tours_and_travel">
                          Tours and Travel
                        </option>
                        <option value="utilities">Utilities-General</option>
                        <option value="utilities_electric_gas_oil_water">
                          Utilities–Electric, Gas, Water, Oil
                        </option>
                      </select>
                      {buisnessCategoryError && (
                        <span className="error">{buisnessCategoryError}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        Business Description
                        <span style={{ color: "#ff0000" }}>*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        cols="50"
                        onChange={handleDescriptionChange}
                      />
                      {descriptionError && (
                        <span className="error">{descriptionError}</span>
                      )}
                      <p className="small">
                        Please give a brief description of the nature of your
                        business. Please include examples of products you sell,
                        the business category you operate under, your customers
                        and the channels you primarily use to conduct your
                        business(Website, offline retail etc).
                      </p>
                    </div>
                  </form>
                </div>
                <div className="ftr-form">
                  {/* <button className="save-next btn btn-primary Button" onClick={() =>formActivation()} > */}
                  <button
                    className="save-next btn btn-primary Button"
                    onClick={handleSubmit}
                  >
                    Save &amp; Next
                  </button>
                </div>
              </div>
            </section>
          </React.Fragment>
        ) : (
          <KycForm3 />
        )}
      </div>
    </React.Fragment>
  );
};

export default injectModels(['application'])(KycForm2) ;

/* file created by sourav mishra */
