import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content  d-flex align-items-center">
              <h2>Privacy Policy & Security</h2>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="tc-page mt-4">
              <p>
                At Dotcoin.thedotverse.com, accessible from
                {"  "}
                <strong>
                  <a href="https://dotcoin.thedotverse.com/">
                    https://dotcoin.thedotverse.com/
                  </a>
                </strong>{" "}
                one of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is
                collected and recorded by Dotcoin.thedotverse.com and how we use
                it. If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us. This
                Privacy Policy applies only to our online activities and is
                valid for visitors to our website concerning the information
                that they shared and/or collect on Dotcoin.thedotverse.com. This
                policy does not apply to any information collected offline or
                via channels other than this website.
              </p>
              <h6> 1. Consent</h6>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
              <h6>2. Information we collect</h6>
              <p>
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information. If you contact us directly, we may receive
                additional information about you such as your name, email
                address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may
                choose to provide. When you register for an Account, we may ask
                for your contact information, including items such as name,
                company name, address, email address, and telephone number.
              </p>

              <h6>3. How we use your information</h6>
              <div>
                We use the information we collect in various ways, including:
                <ul>
                  <li>Provide, operate, and maintain our website</li>
                  <li>Improve, personalize, and expand our website </li>{" "}
                  <li>Understand and analyze how you use our website</li>{" "}
                  <li>
                    Develop new products, services, features, and functionality
                  </li>{" "}
                  <li>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </li>{" "}
                  <li>Send you emails Find and prevent fraud</li>
                </ul>
              </div>

              <h6>4. Log Files</h6>
              <p>
                Dotcoin.
                <strong>
                  <Link to="#"> thedotverse.com </Link>{" "}
                </strong>{" "}
                follows a standard procedure of using log files. These files log
                visitors when they visit websites. All hosting companies do this
                and are a part of hosting services' analytics. The information
                collected by log files includes internet protocol (IP)
                addresses, browser type, Internet Service Provider (ISP), date
                and time stamp, referring/exit pages, and possibly the number of
                clicks. These are not linked to any personally identifiable
                information. The purpose of the information is for analyzing
                trends, administering the site, tracking users' movement on the
                website, and gathering demographic information.
              </p>
              <h6> 5. Cookies and Web Beacons</h6>
              <p>
                Like any other website, Dotcoin.
                <strong>
                  <Link to=""> thedotverse.com </Link>{" "}
                </strong>{" "}
                uses 'cookies'. These cookies are used to store information
                including visitors' preferences, and the pages on the website
                that the visitor accessed or visited. The information is used to
                optimize the users' experience by customizing our web page
                content based on visitors browser type and/or other information.
              </p>
              <h6> 6. Google DoubleClick DART Cookie</h6>
              <p>
                Google is one of the third-party vendors on our site. It also
                uses cookies, known as DART cookies, to serve ads to our site
                visitors based upon their visit to www.website.com and other
                sites on the internet. However, visitors may choose to decline
                the use of DART cookies by visiting the Google ad and content
                network Privacy Policy at the following URL{" "}
                <strong>
                  {" "}
                  <a href=" – https://policies.google.com/technologies/ads">
                    {" "}
                    – https://policies.google.com/technologies/ads
                  </a>
                </strong>
              </p>
              <h6>7. Our Advertising Partners</h6>
              <p>
                Some advertisers on our site may use cookies and web beacons.
                Our advertising partners are listed below. Each of our
                advertising partners has its Privacy Policy for their policies
                on user data. For easier access, we hyperlinked to their Privacy
                Policies below. Google
                <strong>
                  {" "}
                  <a href="https://policies.google.com/technologies/ads">
                    {" "}
                    https://policies.google.com/technologies/ads
                  </a>
                </strong>
              </p>
              <h6>8. Advertising Partners Privacy Policies</h6>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Dotcoin.thedotverse.com. Third-party
                ad servers or ad networks uses technologies like cookies,
                JavaScript, or Web Beacons that are used in their respective
                advertisements and links that appear on Dotcoin.thedotverse.com,
                which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on
                websites that you visit. Note that Dotcoin.thedotverse.com has
                no access to or control over these cookies that are used by
                third-party advertisers.
              </p>

              <h6> 9. Third-Party Privacy Policies</h6>
              <p>
                Dotcoin.
                <strong>
                  <Link to="">thedotverse.com</Link>
                </strong>
                's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt out of certain options.
              </p>
              <p>
                You can choose to disable cookies through your browser options.
                To know more detailed information about cookie management with
                specific web browsers, it can be found on the browsers'
                respective websites.
              </p>
              <p> CCPA Privacy Rights (Do Not Sell My Personal Information)</p>
              <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>
              <p>
                Request that a business that collects a consumer's data disclose
                the categories and specific pieces of personal data that a
                business has collected about consumers.
              </p>
              <p>
                Request that a business deletes any personal data about the
                consumer that a business has collected.
              </p>
              <p>
                Request that a business that sells a consumer's data, not sell
                the consumer's data.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>

              <h6>10. GDPR Data Protection Rights</h6>

              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p>
                The right to access – You have the right to request copies of
                your data. We may charge you a small fee for this service.
              </p>
              <p>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p>
                The right to erasure – You have the right to request that we
                erase your data, under certain conditions. The right to restrict
                processing – You have the right to request that we restrict the
                processing of your data, under certain conditions. The right to
                object to processing – You have the right to object to our
                processing of your data, under certain conditions.
              </p>
              <p>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </p>
              <p>If you make a request, we have one month to respond to you.</p>
              <p>
                If you would like to exercise any of these rights, please
                contact us.
              </p>
              <p>Children's Information</p>
              <p>
                Another part of our priority is adding protection for children
                while using the internet.
              </p>
              <p>
                We encourage parents and guardians to observe, participate in,
                and/or monitor and guide their online activity.
              </p>
              <p className="pb-3">
                Dotcoin.thedotverse.com does not knowingly collect any Personal
                Identifiable Information from children under the age of 13. If
                you think that your child provided this kind of information on
                our website, we strongly encourage you to contact us immediately
                and we will do our best efforts to promptly remove such
                information from our records.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Privacy;
