// import { ethers } from 'ethers';


// const getWalletProvider = async() => {
// 	const walletType = localStorage.getItem("wallet_type");
//     let provider = null;

//     if (window.ethereum && walletType === process.env.REACT_APP_WALLET_TYPE_METAMASK) {
       
       
//         const { ethereum } = window;
//         provider = new ethers.providers.Web3Provider(ethereum);
//      } 

//       return provider; 
// }

// export default getWalletProvider;

import { ethers } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";

const getWalletProvider = async() => {
  let walletType = localStorage.getItem("wallet_type");
  let provider = null;
  if (window.ethereum && walletType === process.env.REACT_APP_WALLET_TYPE_METAMASK) {
      const { ethereum } = window;
      provider = new ethers.providers.Web3Provider(ethereum);
    } 
    else if(walletType === process.env.REACT_APP_WALLET_TYPE_WALLET_CONNECT) {
      const paramWC = {
        rpc: {
          [process.env.REACT_APP_BLOCKCHAIN_ID] : `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}` 
        }
      };
      
      const walletConnectProvider = new WalletConnectProvider(paramWC);

      await walletConnectProvider.enable();
      
      provider = new ethers.providers.Web3Provider(walletConnectProvider);
    }
    else{
      const paramWC = {
        rpc: {
          [process.env
            .REACT_APP_BLOCKCHAIN_ID]: `${process.env.REACT_APP_BLOCKCHAIN_NETWORK}`,
        },
        mobileLinks: [],
      };
      //  provider = new WalletConnectProvider(paramWC);
       const walletConnectProvider = new WalletConnectProvider(paramWC);

       await walletConnectProvider.enable();
       
       provider = new ethers.providers.Web3Provider(walletConnectProvider);


      // await provider.enable();
    }

    return provider; 
}

export default getWalletProvider;