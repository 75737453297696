import React from "react";
import { useState } from "react";
import KycForm2 from "./KycForm2";
import { toast } from "react-toastify";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Form, Alert } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useAuth } from "../../../contexts/AuthContext";
import { Link } from "react-router-dom";


const PhoneVerified = () => {

  const [activeForm, setactiveForm] = useState(false);
  // const [phone, setPhone] = useState("");
  // const [phoneError, setPhoneError] = useState("");
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const { setUpRecaptha } = useAuth();


   // const formActivation = () =>{
 //   setactiveForm(true)
  // }

  const getOtp = async (e) => { 
    e.preventDefault();
    console.log(number);
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(number);
      console.log(response)
      setResult(response);
      setFlag(true);
    } catch (err) {
      const errorMessage = err.code.split("auth")[1];
      setError(errorMessage);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) {
      toast.error("Please enter otp ", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      try {
        console.log(otp,"otp")
        await result.confirm(otp);
        setactiveForm(true)
        // navigate("/home");
        console.log("otp match")
      } catch (err) {
        const errorMessage = err.code.split("auth")[1];
        setError(errorMessage);
      }
    }
  
  };



  return (
    <React.Fragment>
    <div className="form-content">
         { !activeForm ?
    <React.Fragment>
      <section className="form-content">
            <div className="col-md-8">
              <div className="merchant-kyc-div Info">
                <h2>Phone verification</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
             <Form.Group className="mb-3" controlId="formBasicEmail">
            <PhoneInput
              defaultCountry="IN"
              value={number}
              onChange={setNumber}
              placeholder="Enter Phone Number"
            />
            <div id="recaptcha-container"></div>
          </Form.Group>
          <div className="button-right">
            {/* <Link to="/dashboard">
              <Button variant="secondary">Cancel</Button>
            </Link> */}
            &nbsp;
            <Button type="submit" variant="primary">
              Send Otp
            </Button>
          </div>
        </Form>

        <Form onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
          <Form.Group className="mb-3" controlId="formBasicOtp">
            <Form.Control
              type="otp"
              placeholder="Enter OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
          </Form.Group>
          <div className="button-right">
            {/* <Link to="/">
              <Button variant="secondary">Cancel</Button>
            </Link> */}
            &nbsp;
            <Button type="submit" variant="primary">
              Verify
            </Button>
          </div>
        </Form>
              </div>
              <div className="ftr-form">
              </div>
            </div>
      </section>
    </React.Fragment>
   :
   <KycForm2 />
  } 

</div>
</React.Fragment>
);
};

export default PhoneVerified;
