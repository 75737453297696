import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { DashboardLayout } from './layouts';
import { Routes } from './constants';
import {JWT} from './shared';


const CustomDashboardRoute = ({component:Component, ...rest}) => { 
    const isValidToken = JWT.isValidToken();
    // const userJson = localStorage.getItem("user");
    // const user = userJson ? JSON.parse(userJson) : null; 
     return (
        <Route {...rest} render={(props)=>{
            return (
                <React.Fragment>
                   {!isValidToken ? <Redirect to={Routes.LOGIN} /> : <DashboardLayout><Component {...props} /></DashboardLayout>}
                </React.Fragment>
            )
        }} />
    );
};

export default CustomDashboardRoute;