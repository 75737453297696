import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../constants";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className=" col-xl-5 col-lg-5 col-md-6">
            <div className="footer-content">
              <img src="/assets/img/dotsexchange-f.png" alt="" />

              <p>
                Something beyond a Crypto Payment Processor. Let the
                cryptocurrencies unlock their full potential. Accept crypto
                payments with a minimal fee. Spend cryptocurrency at online
                stores directly with complete transparency and scalability at
                the highest level.
              </p>
            </div>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-6">
            <div className="footer-content">
              <h6>Company</h6>
              <ul>
                <li>
                  <Link to={Routes.EXPLORE}>Explore</Link>
                </li>
                <li>
                  <Link to={Routes.MISSION}>Our Mission</Link>
                </li>
                <li>
                  <Link to={Routes.ABOUTUS}>About Us</Link>
                </li>
                <li>
                  <Link to={Routes.TERMCONDITIONS}>Terms & Conditons</Link>
                </li>
                {/* <li><Link to={Routes.CONTACT}> Contact Us </Link></li> */}
              </ul>
            </div>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-6">
            <div className="footer-content">
              <h6>Additional Information</h6>
              <ul>
                <li>
                  <Link to={Routes.PRIVACY}>Privacy Policy</Link>
                </li>
                <li>
                  <Link to={Routes.FAQ}> FAQs </Link>
                </li>
                <li>
                  <Link to={Routes.BLOGS}> Blogs </Link>
                </li>
                <li>
                  <Link to={Routes.CONTACT}> Contact Us </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6">
            <div className="footer-content">
              <h6>Contact Us</h6>

              <div className="contact-link">
                <i className="fa-solid fa-house"></i>
                <p>
                  Unit 2, Albourne Court, Henfield Road, Albourne, West Sussex,
                  BN6 9FF, UK
                </p>
              </div>

              <div className="contact-link">
                <i className="fa-solid fa-phone"></i>
                <p>
                  <Link to="#"> 1 800 600 5858 </Link>
                </p>
              </div>

              <div className="contact-link">
                <i className="fa-solid fa-envelope"></i>
                <p>
                  <Link to="#"> support@dotsquares.com </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="ftr-btm">
              <p>©2022 DotPay, All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
