import React, { useEffect } from "react";

const Explore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content  d-flex align-items-center">
              <h2> Explore</h2>
            </div>
          </div>
        </div>
      </section>

      <div className="offer-trusted expoler">
        <div className="container">
          <h2>ONE ACCOUNT FOR YOUR OWN AND BUSINESS NEEDS</h2>
          <h6>Accept, trade, and manage cryptos</h6>
          <p>
            Whether you’re a trader, merchant, developer, or just someone who
            loves crypto - everyone can get benefits from the DotPay
            cryptocurrency payment processor.
          </p>
          <div className="row">
            <div className="col-md-4">
              <div className="card-secure">
                <img alt="" src="/assets/img/easy.png"></img>
                <h6>Easy To Transact</h6>
                <p>
                  {" "}
                  Transfer your business funds in no time. The transfers are
                  instantaneous, carry no fee, work anywhere in the world, and
                  are private.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-secure">
                <img alt="" src="/assets/img/bitc.png"></img>
                <h6>No Fear Of Loss</h6>
                <p>
                  {" "}
                  Unlike any form of payment transfer, Dotpay is independent,
                  transparent, and completely decentralized with no third-party
                  involvement.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card-secure">
                <img alt="" src="/assets/img/secure.png"></img>
                <h6>Full proof Security</h6>
                <p>
                  {" "}
                  DotPay is based on distributed ledger technology. It is
                  considered to be one of the best ways of securing business
                  transactions.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Explore;
