import React from "react";
import { useHistory } from "react-router-dom";

const Introduction = () => {
  const history = useHistory();

  const routeChange = () => {
    history.push("/login");
  };

  return (
    <section className="currency-start">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6"
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="about-cry">
              <h6>Welcome To DotPay</h6>

              <h2>Smart and Secure Way To Do Business Crypto Transactions</h2>

              <p>
                DotPay is one of the most trusted cryptocurrency Payment
                Processors. It lets you securely connect, trade, store, and
                transact your business payments, & blocks suspicious accounts to
                ensure user safety.
              </p>
              <button onClick={routeChange}>Get Started Now</button>
            </div>
          </div>

          <div className="col-md-6">
            <div className="about-cry">
              <img src="/assets/img/Bitcoin-Analysis2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
