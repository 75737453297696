import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { Routes } from "../../constants";
import { injectModels } from "../../redux/injectModels";
import { Icon } from 'react-icons-kit'
import {eye} from 'react-icons-kit/feather/eye'
import {eyeOff} from 'react-icons-kit/feather/eyeOff'

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passRegex =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

const Login = (props) => {
  //usestate constants
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [error, setError] = useState("");
  const [type, setType]=useState('password');
  const [icon, setIcon]=useState(eyeOff);

  const history = useHistory();

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      //validations for email
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Enter a valid Email ");
    } else {
      setEmailError("");
    }
    setEmail(val.trim());
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setPassError("password is required");
    } else if (!passRegex.test(val)) {
      //password validation
      setPassError("Enter a valid password ");
    } else {
      setPassError("");
    }
    setPassword(val);
  };

  const handleToggle=()=>{    
    if(type==='password'){
      setIcon(eye);      
      setType('text');
    }
    else{
      setIcon(eyeOff);     
      setType('password');
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a Valid Email");
      valid = false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPassError("password is required");
      valid = false;
    } else {
      setPassError("");
    }

    if (!valid) {
      return;
    } else {
      setError("");
      props.application.setLoading(true);
      try {
        const response = await props.auth.userLogin(email, password);
        if (response) {
          props.application.setLoading(false);
          toast.success("Log In successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          history.push("/dashboard");
        }
      } catch (error) {
        // console.log(error,"error");
        props.application.setLoading(false);
        toast.error(error.code.split("auth/")[1], {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      /* Login Logic */
      //  await  login(email, password)
      //     .then((userCredential) => {
      //       if (userCredential.user) {
      //         localStorage.setItem("user", JSON.stringify(userCredential.user));
      //       }
      //       // Signed in
      //       setLoader(true);
      //       e.target.reset();
      //       toast.success("Log In successfully!", {
      //         position: toast.POSITION.TOP_CENTER,
      //       });
      //       history.push("/dashboard");
      //     })
      //     .catch((error) => {
      //       setLoader(false);
      //       e.target.reset();
      //       toast.error(error.code.split("auth")[1], {
      //         position: toast.POSITION.TOP_CENTER,
      //       });
      //     });
    }
  };

  // <!-- return statement -->

  return (
    <section className="contact-us3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="signup-logo">
              <Link to="/">
                {" "}
                <img src="/assets/img/signup-logo.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container contact-bg-color login-content">
        <div className="row  ">
          <div className="col-md-6 ">
            <div className="img-select1">
              <img src="/assets/img/login.png" alt="" />
            </div>
          </div>
          <div className="col-md-6 hello">
            <div
              className="contact-form-content1"
              style={{ background: "none", boxShadow: "none" }}
            >
              <div className="signup-logo1">
                <h2>Login</h2>
              </div>
              <form className="mt-1" onSubmit={handleSubmit}>
                <div className="col-md-12 pl-0">
                  <div className="searchformfld">
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" "
                      onChange={handleEmailChange}
                    />
                    <label>
                      Email Address<span style={{ color: "#ff0000" }}>*</span>
                    </label>
                    {emailError && <span className="error">{emailError}</span>}
                  </div>
                </div>
                <div className="col-md-12 pl-0">
                  <div className="searchformfld">
                    <input
                      type={type}
                      className="form-control"
                      placeholder=" "
                      onChange={handlePasswordChange}
                    />
                    <label>
                      Password<span style={{ color: "#ff0000" }}>*</span>
                    </label>
                    <div className="handle">
                      <span onClick={handleToggle}><Icon icon={icon}/></span> </div>
                    {passError && <span className="error">{passError}</span>}
                  </div>
                </div>
                {error && (
                  <div className="col-xs-12 col-md-12 col-lg-12">
                    <div className="alert alert-danger">{error}</div>
                  </div>
                )}
                <div className="submit-button">
                  <button type="submit" className="btn btn-primary">
                    Submit &nbsp;
                  </button>
                </div>
                <div className="user-alreay">
                  <Link to={Routes.FORGOTPASSWORD}>Forgot Password</Link>
                </div>
                <div className="user-alreay">
                  <span className="text-div ">New to dotpay? </span>{" "}&nbsp;
                  <Link to={Routes.SIGNUPONE}>Signup</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectModels(["auth", "application"])(Login);

/* file created by sourav mishra  on dated-09/06/22 */
