import React,{useEffect} from 'react'

const Blogs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
  <React.Fragment>
      <section className='slider'>
        <div className="container">

          <div className="row">
            <div className="slider-content  d-flex align-items-center">
            <h2>#Blogs</h2>
            </div>
          </div></div>
      </section>

  
    {/* <li><Link to={Routes.CONTACT}> Contact Us </Link></li> */}
    <div>Upcoming Feature</div>
    </React.Fragment>

  )
}

export default Blogs