import React,{useEffect} from "react";

const Aboutus = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content  d-flex align-items-center">
              <h2>About Us</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="whta-crypto  crypt-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-content">
                <h6>
                  Customers can choose from such purchasing methods as
                  credit/debit cards, SEPA bank transfer, or mobile balance, or
                  sell their coins for fiat.
                </h6>
                <p>
                  DotPay is a UK-based fintech company. The payment gateway
                  offers cryptocurrency payment processing services for
                  businesses of any size. Permission-based account management
                  with brand-new billing features is just a few reasons why
                  Dotpay is on the way to becoming a go-to payment processor for
                  many. The company brings cryptocurrencies closer to mass
                  adoption by offering several methods to set up cryptocurrency
                  payments for any website or physical store. Options include
                  payment buttons, API integration, and official plugins for the
                  biggest e-commerce platforms. DotPay merchant services, DotPay
                  also provides a platform for buying and selling numerous
                  crypto-assets with a single payment.
                </p>
              </div>
            </div>

            <div className="col-md-6">
              <div className="about-content">
                <img alt="" src="assets/img/about-us.jpg"></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Aboutus;
