import React,{useEffect} from "react";

const TermsCondition = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content  d-flex align-items-center">
              <h2>Terms And Conditions</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="terms-condtion">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="tc-page">
                <h6>1.GENERAL TERMS</h6>
                <p>
                  <strong>1.</strong> &nbsp; These Dotpay Merchant General Terms
                  & Conditions (hereinafter referred to as 
                  <strong>“General Terms” </strong>) set forth the terms and
                  conditions applicable to the provision of Services (as defined
                  below) by Dotpay (hereinafter, “Dotpay” or “we” or “us”) to
                  merchants with registered businesses (hereinafter, “Merchant”
                  or “you” or “your.”){" "}
                </p>

                <p>
                  <strong>2.</strong> &nbsp;These General Terms explain many of
                  your responsibilities to us and our responsibilities to you,
                  how and when these General Terms can be terminated, the extent
                  of our liability to you, and how and where disputes between
                  you and us shall be resolved.
                </p>

                <p>
                  <strong>3.</strong> &nbsp;By accepting the Dotpay Service, as
                  defined below, you agree and acknowledge that you have read
                  and consented to these General Terms and shall undertake to
                  adhere to them.
                </p>

                <p>
                  <strong>4.</strong> &nbsp;These General Terms, which are
                  currently in force, are always available on our website,  We
                  reserve the right to update these General Terms from time to
                  time with or without notice to you.
                </p>

                <h6>2.THE SERVICE</h6>
                <p>
                  <strong>1.</strong> &nbsp;Dotpay is a cryptocurrency payment
                  processor enabling you to accept cryptocurrencies as a form of
                  payment in exchange for goods or services you sell to your
                  customers. As used herein, “Service” includes Dotpay’s receipt
                  of cryptocurrency payments from your customers on your behalf.
                </p>

                <p>
                  <strong>2.</strong> &nbsp;The Service shall also be defined to
                  include the Dotpay System (“System”), defined herein as the
                  complete suite of digital tools provided to you by Dotpay
                  under these General Terms, including but not limited to the
                  Dotpay website, mobile application, and related interfaces.
                </p>

                <p>
                  <strong>3.</strong> &nbsp;The Service also includes our
                  Settlement of the cryptocurrency transactions we process on
                  your behalf, the terms of which are outlined in Section 5,
                  below.
                </p>

                <p>
                  <strong>4.</strong> &nbsp;Dotpay does not provide services
                  other than the Service, for instance, cryptocurrency exchange,
                  custody, or remittance services.
                </p>

                <p>
                  <strong>5.</strong> &nbsp;Subject to these General Terms,
                  including but not limited to the lists of Prohibited and
                  Restricted Businesses set forth below, the Service is only
                  available to businesses that sell products or services and
                  registered charitable organizations that accept donations
                  consistent with their charitable missions.
                </p>

                <p>
                  <strong>6.</strong> &nbsp;By using the Service, you authorize
                  Dotpay to receive cryptocurrency payments from your customers
                  (i.e. as an intermediary) and to take any actions necessary to
                  provide the Service in compliance with applicable laws and
                  regulations.
                </p>

                <p>
                  <strong>7.</strong> &nbsp;For the avoidance of doubt, by
                  providing the Service to you, Dotpay’s receipt of
                  cryptocurrency on your behalf shall be the same as if the
                  payment was received directly from you. Upon our receipt of
                  cryptocurrency on your behalf, each respective customer’s
                  outstanding obligations to you shall be extinguished to the
                  extent of the payment, and your obligation to complete the
                  customer’s purchase shall be triggered, irrespective of any
                  subsequent events.
                </p>

                <h6>3.YOUR MERCHANT ACCOUNT</h6>
                <p>
                  <strong>1.Account Opening and Registration</strong>
                </p>

                <p>
                  <strong>1.</strong> &nbsp;As used herein, “Account” means the
                  virtual interface provided to you by Dotpay that allows Dotpay
                  to provide the service to you. Your Account is accessible via
                  the System by using your registration email and long-term
                  password (if applicable, one-time password as well) to log in.
                  The use of the term “Account” in these General Terms, or
                  elsewhere within the Dotpay system, shall not be interpreted
                  as Dotpay serving as a depository or otherwise holding
                  cryptocurrency or fiat currency on your behalf.
                </p>

                <p>
                  <strong>2.</strong> &nbsp;By using the Service, you represent
                  and warrant that the information you provide at the time of
                  opening your Account is accurate and complete and that you
                  will inform us within ten (10) days of any changes to such
                  information.
                </p>

                <p>
                  <strong>3.</strong> &nbsp;We will review and assess the
                  information and documents submitted to us you. Upon our
                  receipt and approval of the information and documents you
                  provide, which shall not be unreasonably withheld, we will
                  grant you access to the Service, which you can begin using
                  immediately, subject to these General Terms.
                </p>

                <p>
                  <strong>4.</strong> &nbsp;Dotpay has the right to reject your
                  Account registration, to later close your Account, or to
                  restrict the provision of the Service to you if you do not
                  provide and maintain accurate, complete, and satisfactory
                  information.
                </p>

                <strong>
                  <p>2.Your Right to Close Your Account</p>
                </strong>

                <p>
                  <strong>1.</strong> &nbsp;You may close your Account anytime.
                  You will still be obligated to us for any fees incurred, if
                  applicable, before the closure, and we will remit to you funds
                  not yet paid to you and associated with pre-closure sales. If
                  your Account balance is below our documented minimum transfer
                  amount, you may be responsible for any applicable transaction
                  fees that may be incurred in the fund's transfer.
                </p>

                <p>
                  <strong>
                    3.Our Right to Decline, Suspend, or Close Your Account{" "}
                  </strong>
                </p>
                <p>
                  <strong>1.</strong> &nbsp;We reserve the right to decline to
                  provide the Service to you or immediately suspend or close
                  your Account, without notice, if we learn or reasonably
                  suspect, in our sole discretion, that you will use or have
                  used your Account to engage in unlawful or improper activity
                  in violation of applicable laws or regulations, these General
                  Terms, and/or our other applicable terms of use.
                </p>

                <p>
                  <strong>2.</strong> &nbsp;We reserve the right to monitor,
                  review, retain, and/or disclose any information we deem
                  necessary to satisfy any applicable law, regulation, sanctions
                  program, legal process, or governmental request.
                </p>

                <p>
                  <strong>3.</strong> &nbsp;Additionally, if your Account does
                  not have a paid transaction for more than twelve consecutive
                  months, we reserve the right to suspend the Account and
                  automatically disable processing capabilities. You will
                  receive information about the process to reactivate the
                  Account.
                </p>
                <p>
                  <strong>4. </strong>&nbsp;Dotpay also reserves the right to
                  terminate accounts that receive excessive customer complaints.
                </p>
                <p>
                  <strong>4.Effect of Account Closure</strong>
                </p>
                <p>
                  <strong> 1.</strong>&nbsp; If your Account is closed for any
                  reason, you agree: (a) to continue to be bound by surviving
                  portions of these General Terms, (b) to immediately stop using
                  the Services, (c) that the license provided under these
                  General Terms shall end, (d) to remove from your website, apps
                  and marketing materials and will discontinue using any Dotpay
                  service marks, logos or another branding, (e) that we will
                  retain certain information and Account data as required under
                  applicable laws and regulations, and (f) that we shall not be
                  liable to you or any third party for termination of access to
                  the Services, closure of an Account, or retention of
                  information or Account data.
                </p>

                <p>
                  <strong>5.Prohibited Uses</strong>
                </p>
                <p>
                  <strong> 1.</strong>&nbsp;It is strictly prohibited to use any
                  of the Services for any of the following:
                </p>
                <ul>
                  <li>
                    to conduct or engage in any illegal or unlawful activity;
                  </li>

                  <li>
                    to hide or disguise the proceeds of any illegal or unlawful
                    activity;
                  </li>
                  <li>to engage in any fraudulent or malicious activity;</li>

                  <li>
                    to control or use an Account that you have not been
                    authorized to control or use;
                  </li>
                  <li>
                    to allow anyone who is not a duly authorized person to have
                    access to or use your Account;
                  </li>

                  <li>
                    to disable, disassemble, reverse-engineer, or decompile the
                    Dotpay System;
                  </li>

                  <li>
                    to act in a way that imposes an unreasonable or
                    disproportionately large load on our infrastructure, or
                    detrimentally interfere with, intercept, or expropriate any
                    system, data, or information; to transmit or upload any
                    material to the Dotpay System or the Website that contains
                    viruses, trojan horses, worms, or any other harmful or
                    deleterious programs; to attempt to gain unauthorized access
                    to the Dotpay System or the Website, Accounts of other
                    persons, computer systems or networks connected to the
                    Dotpay System or the Website, through password mining or any
                    other means; to use the Account information of another party
                    to access or use the Dotpay System or the Website
                  </li>
                  <li>
                    to infringe on our proprietary intellectual property or the
                    intellectual property of any third parties;
                  </li>
                  <li>
                    to conduct or engage in activity in a way that we reasonably
                    believe might harm our ability to provide Service;
                  </li>

                  <li>
                    to engage in any other use or activity that breaches these
                    General Terms or is not in conformity with sustainable
                    activities of the Company, ensuring human rights,
                    transparency, gender equality, morals and ethics, or other
                    activity deemed unacceptable by the Company.
                  </li>
                  <li>
                    We reserve the right, without any prior notice or
                    explanation, to refuse any person from using our Services or
                    to terminate an existing relationship with you if we
                    reasonably believe that the activity being carried out by
                    that person/you violate any of the prohibitions specified in
                    this section.
                  </li>
                </ul>
                <p>
                  <strong>6.Prohibited Businesses</strong>
                </p>
                <p>
                  <strong>1. </strong> We are unable to provide the Service to
                  the following categories of Merchants, or when any category of
                  Merchant engages in sales of the following prohibited items:
                </p>
                <ul>
                  <li>
                    Drugs and drug paraphernalia (e.g., narcotics, controlled
                    substances, and any equipment designed for making or using
                    drugs);
                  </li>
                  <li>
                    Marijuana/cannabis dispensaries and related products and
                    businesses;
                  </li>

                  <li>
                    Weapons, munitions, gunpowder, and other explosives
                    (including fireworks);
                  </li>

                  <li>Toxic, flammable, and radioactive materials;</li>

                  <li>Pseudo-pharmaceuticals;</li>
                  <li>Substances designed to mimic illegal drugs;</li>

                  <li>Sexually explicit content;</li>

                  <li>Sexually-related services;</li>

                  <li>
                    Pyramid and investment schemes, multi-level marketing
                    schemes, and other unfair, predatory, or deceptive
                    practices;
                  </li>

                  <li>
                    Items used for speculation or hedging purposes (such as
                    derivatives);
                  </li>

                  <li>Credit and collection services;</li>

                  <li>
                    Items that infringe or violate any intellectual property
                    rights such as copyrights, trademarks, trade secrets, or
                    patents, including counterfeit or unauthorized goods;
                  </li>
                  <li>
                    Products and services with varying legal status from state
                    to state;
                  </li>
                  <li>
                    Transactions that disclose the personal information of third
                    parties in violation of applicable law;
                  </li>

                  <li>Transactions related to cloud mining;</li>

                  <li>
                    Deferred presentment providers (also known as “payday
                    lenders”);
                  </li>

                  <li>
                    Transactions associated with purchases of annuities or
                    lottery contracts, lay-away systems, banking, offshore
                    banking, transactions to finance, investing,
                    investment-related products; and
                  </li>
                  <li>
                    Transactions involving gambling or any activity requiring an
                    entry fee and a prize, including but not limited to casino
                    games, sports betting, horse or greyhound racing, lottery
                    tickets, other ventures that facilitate gambling, and
                    sweepstakes.
                  </li>
                </ul>
                <p>
                  <strong>2.</strong> We reserve the right, without any prior
                  notice or explanation, to refuse any person from using our
                  Service or to terminate an existing relationship with you if
                  we reasonably believe that the activity being carried out by
                  that person/you violate any of the prohibitions specified in
                  this section.
                </p>
                <p>
                  <strong>7. Your Password</strong>
                </p>
                <p>
                  <strong>1.</strong>&nbsp; You will choose a password when
                  registering for your Account. You are responsible for
                  maintaining the confidentiality of your password and Account
                  access credentials. You are fully responsible for all
                  activities that occur with the use of your password or
                  Account. Please notify us immediately if you become aware of
                  any unauthorized use of your password or Account or any other
                  breach of your security that may compromise your password or
                  Account. You may not allow third parties or unauthorized users
                  to use your Account. We will not be liable for any loss that
                  you may incur as a result of someone else using your password
                  or Account, either with or without your knowledge. You may
                  notify us of a security breach regarding your password or
                  Account by emailing us.
                </p>

                <p>
                  <strong>8.Security.</strong>
                </p>
                <p>
                  <strong>1.</strong>&nbsp; We have implemented security
                  measures designed to protect information from accidental loss
                  and unauthorized access, use, alteration, or disclosure. Our
                  security measures include risk assessments and controls for
                  the following: application and system access, system and
                  application development and maintenance, acceptable use, data
                  classification, incident response, disaster recovery, business
                  continuity, and security training. We cannot guarantee that
                  unauthorized persons will never gain access to your
                  information, and you acknowledge that you provide your
                  information at your own risk, except as otherwise provided by
                  applicable law. If we reasonably suspect or we become aware of
                  a breach of our security measures that may have impacted you,
                  your Account, or your information, we will provide you with
                  the notice within 72 hours of gaining such suspicion or
                  becoming aware of such breach. Such notice will be provided to
                  your email address included in your Merchant profile.
                </p>
                <h6>4.SETTLEMENT</h6>
                <p>
                  <strong>1. Methods of Settlement.</strong>
                </p>
                <p>
                  <strong>1.</strong>&nbsp; We will clear the payments we
                  receive from your customers on your behalf over the
                  cryptocurrency peer-to-peer payment network and credit your
                  Account ledger, according to the settlement preferences that
                  you choose in the System.
                </p>

                <p>
                  <strong>2.</strong>&nbsp; You can receive a settlement in a
                  local currency, in any of the supported cryptocurrencies, or a
                  mixture of both, and we have no right to impose any
                  cryptocurrency over local currency, as your choice in this
                  respect supersedes any of our preferences.
                </p>
                <p>
                  <strong> 3. </strong>An Account ledger will be created for
                  each currency selected for settlement by your settlement
                  preferences. If you change your settlement preferences, those
                  changes will be effective for Account ledger balances that are
                  received after the date of the change. Any Account ledger
                  balances dating from before the preference change will settle
                  under your prior settlement preferences.
                </p>

                <p>
                  <strong>1. Settlements to Your Bank Account.</strong>
                </p>
                <p>
                  <strong>1.</strong> If you wish to receive direct deposits,
                  you must provide valid bank account information and keep the
                  such information current. Each business day, we will send a
                  direct deposit to your bank account to clear out your
                  accumulated Account ledger balance, provided that (a) the
                  settlement minimums are met, and (b) funds will be deposited
                  in your bank account within two business days following the
                  settlement order. We reserve the right to charge you
                  applicable wire fees. You must provide us with written notice
                  at least one (1) business day before closing your bank
                  account. If you wish to continue to receive direct deposits,
                  you must provide us with information for a substitute bank
                  account. You are solely liable for all fees and costs
                  associated with your bank account and for all overdrafts. You
                  are also liable to us for any fees that we may incur based on
                  your provision of inaccurate information or instructions. You
                  authorize us to initiate electronic credits to your bank
                  account at any time, as necessary to process your
                  transactions. We will not be liable for any delays in receipt
                  of funds or errors in bank account entries caused by third
                  parties, nor will we be liable or responsible for any errors
                  in the account or address information that you have provided
                  to us.
                </p>

                <p>
                  <strong>3. Cryptocurrency Settlements.</strong>
                </p>
                <p>
                  3. If your settlement preference includes cryptocurrency, any
                  supported cryptocurrency accepted by Dotpay on your behalf
                  must be settled to a cryptocurrency wallet that you provide.
                  Payments in any of the supported cryptocurrencies will be sent
                  to your designated wallet address, generally by the end of the
                  day following the relevant and provided that the settlement
                  minimum is met. We are not liable for any losses incurred as a
                  result of improperly reported or designated wallet addresses
                  that you provide. You assume the volatility risk of the
                  cryptocurrencies in which settlement occurs. If any of the
                  supported cryptocurrencies are the chosen settlement medium,
                  you assume the volatility risk associated with the specified
                  cryptocurrency.
                </p>

                <p>
                  <strong>4.Limitation of Liability.</strong>
                </p>
                <p>
                  <strong>1.</strong> You understand and agree that you are
                  solely responsible for the accuracy of any information and
                  instructions that you provide to us and for any actions that
                  are carried out in reliance on such information and
                  instructions.
                </p>

                <p>
                  <strong>2.</strong> By using the Service, you acknowledge and
                  agree that transfers of cryptocurrency are final and cannot be
                  reversed once they have been broadcast to the relevant
                  network, even when in a pending state. Furthermore, you
                  acknowledge and agree that it is your responsibility to verify
                  all personal, banking, and wallet information before using the
                  Service and that we shall bear no liability or responsibility
                  if any such information is incorrect.
                </p>

                <p>
                  <strong>3.</strong> You also acknowledge and agree that Dotpay
                  will rely upon the personal, banking, and wallet information
                  you provide to us when opening and managing your Account. It
                  is your responsibility to verify all such information, and we
                  shall bear no liability or responsibility in the event you
                  enter incorrect information.
                </p>

                <h6>5.FEES</h6>
                <p>
                  {" "}
                  <strong>1.Dotpay Fees</strong>
                </p>
                <p>
                  <strong>1.</strong> We charge you a processing fee for each
                  transaction we process on your behalf. These fees are deducted
                  from your Account ledger balances.
                </p>
                <p>
                  <strong>2.</strong>The dotpay processing fee is listed for
                  each invoice in your Dashboard and the API output. We reserve
                  the right to change our processing fee. We will provide not
                  less than thirty (30) days advance notice of such a change,
                  and your continued use of the Service following the period of
                  advance notice of the fee change constitutes your acceptance
                  of the such change.
                </p>
                <p>
                  <strong>3.</strong>Our fees shall be in addition to any miner
                  fees and network costs.
                </p>

                <h6>6.REFUNDS</h6>

                <p>
                  <strong>1.</strong> You authorize us to process refund
                  requests and issue refunds on your behalf from your Account.
                  We reserve the right, without any explanation or reason, to
                  not issue a refund.
                </p>

                <p>
                  <strong>2.</strong>In case of underpayment, your customer can
                  request a refund provided that his/her/its order to you are
                  not marked as “paid” within our System.
                </p>

                <p>
                  <strong>3.</strong>In case of overpayment, your customer can
                  request only a refund of an overpaid amount.
                </p>

                <p>
                  <strong>4.</strong> We reserve the right to establish a
                  minimum amount that can be refunded on your behalf. If the
                  amount requested is lower than the minimum refundable amount,
                  we reserve the right to not issue the refund.
                </p>

                <p>
                  <strong>5.</strong>Any requests for refunds can be made to us
                  until we decide to transfer the refundable amounts to you. If
                  no requests for refunds are received by us until we decide to
                  transfer the refundable amounts to you, we will transfer the
                  refundable amounts to you and from that moment you will be
                  fully responsible for any refunds to your customers.
                </p>
                <p>
                  <strong>6.</strong>
                  We may charge fees and deduct other costs for processing
                  refunds on your behalf as indicated on our Website
                </p>
                <p>
                  <strong>7.</strong>
                  Further terms of our refund policy may be indicated on our
                  Website
                </p>
                <p>
                  <strong>8.</strong> We reserve the right to decline refund
                  requests submitted more than 90 days after the payment was
                  made.
                </p>
                <h6>7.COMPLAINTS</h6>
                <p>
                  <strong> 1. Customer Complaints.</strong>
                </p>
                <p>
                  If we receive a complaint from a customer and it relates to
                  you or the purchased goods/services, we will send it to you
                  for resolution. We reserve the right to terminate Accounts
                  that receive excessive complaints.
                </p>
                <p>
                  <strong>2.Merchant Complaints.</strong>
                </p>
                <p>
                  Merchants wishing to register complaints about Dotpay or the
                  Service may do so on our Website.
                </p>
                <h6>8.REPRESENTATIONS AND WARRANTIES</h6>
                <p>
                  <strong>1.You represent and warrant that</strong>
                </p>
                <p>
                  <strong>1.</strong>
                  The individual accepting these terms on behalf of the Merchant
                  is of the age of majority, and has all necessary power,
                  capacity, and authority to bind the Merchant hereto;
                </p>
                <p>
                  <strong>2.</strong>You have all necessary rights, power,
                  authority, and ability to enter into and fulfill your
                  obligations under these Terms and your use of the Service;
                </p>
                <p>
                  <strong>3.</strong>Your business entity is registered, and is
                  duly organized, in good standing under the laws of its
                  formation, and is duly qualified and in good standing in each
                  jurisdiction in which the conduct of its business requires it
                  to so qualify;
                </p>
                <p>
                  <strong>4.</strong>
                  Your use of the Service will not contravene any applicable
                  international, federal, state, or local laws or regulations,
                  including tax laws and regulations;
                </p>
                <p>
                  <strong>5.</strong>
                  You ensure that your use of the Service does and at all times
                  will comply with all applicable anti-money
                  laundering/anti-terrorist funding compliance laws and
                  regulations, including, without limitation, all
                  OFAC-administered sanctions programs and any other sanctions
                  programs that may apply to you based on the jurisdiction(s) in
                  which you operate your business and serve your customers;
                </p>
                <p>
                  <strong>6.</strong>
                  Your use of the Service will violate neither these Terms nor
                  any other applicable terms of use; and
                </p>
                <p>
                  <strong>7.</strong>
                  All information that you have provided to us, and may from
                  time to time provide to us, is and shall continue to be true
                  and complete, and shall be timely updated and corrected to
                  maintain its status as true and complete.
                </p>

                <h6>9.NO INVESTMENT ADVICE</h6>
                <p>
                  <strong>1.You acknowledge and agree that:</strong>
                </p>
                <p>
                  <strong>1.</strong>No communication, written or oral, made by
                  or received from us shall be deemed to be an assurance or
                  guarantee as to the expected results of the Service;
                </p>
                <p>
                  <strong>2.</strong>No information provided to you by us shall
                  be considered or construed as investment advice, and no
                  amounts of cryptocurrency you receive from customers shall be
                  attributable to any advice we provided; and
                </p>
                <p>
                  <strong>3.</strong>Dotpay is not acting as your fiduciary or
                  advisor concerning the Service.
                </p>
                <h6>10.DISCLAIMER OF WARRANTY</h6>
                <p>
                  WE PROVIDE THE SERVICE ON AN “AS IS” AND “AS AVAILABLE” BASIS,
                  AND YOUR USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE
                  MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE PROVIDE THE
                  SERVICE WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                  IMPLIED (INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                  NON-INFRINGEMENT). WITHOUT LIMITING THE FOREGOING, WE DO NOT
                  WARRANT THAT THE SERVICES (AND OUR WEBSITE): WILL OPERATE
                  ERROR-FREE OR THAT DEFECTS OR ERRORS WILL BE CORRECTED; WILL
                  MEET YOUR REQUIREMENTS OR WILL BE AVAILABLE, UNINTERRUPTED OR
                  SECURE AT ANY PARTICULAR TIME OR LOCATION; ARE FREE FROM
                  VIRUSES OR OTHER HARMFUL CONTENT.
                </p>
                <h6>11.CONFIDENTIALITY</h6>
                <p>
                  <strong>1.</strong>
                  The Parties acknowledge that, from time to time, the Party
                  (the “Disclosing Party ”) may disclose to the other Party (the
                  “Receiving Party ”) either directly or indirectly by way of
                  using/rendering the Services, electronically, in writing,
                  orally or otherwise, information which is proprietary or
                  confidential or which would, under the circumstances, be
                  understood by a reasonable person to be proprietary and
                  non-public, including without limitation, the information on
                  the content of transactions with the Virtual Assets, security
                  requirements related to transactions with a Virtual Asset,
                  technical data, know-how, trade secrets related to activities
                  of any of the Parties, the non-public Company information and
                  all unpublished service manuals, information, data and other
                  similar materials or records provided by the Party to the
                  other Party under these General Terms or otherwise
                  (“Confidential Information ”).
                </p>
                <p>
                  <strong>2.</strong>
                  The Receiving Party shall use such Confidential Information
                  solely for fulfilling its responsibilities and obligations
                  under these General Terms and for no other purposes. The
                  Receiving Party shall retain such Confidential Information in
                  strict confidence and shall not disclose it to any third party
                  without the Disclosing Party’s written consent, except to the
                  third party(-ies) engaged/contacted by the Company to ensure
                  the provision of Services/compliance with applicable
                  legislation as well as to law enforcement.
                </p>
                <ul>
                  <ol>
                    <strong>1.</strong> Each Party shall use at least the same
                    procedures and degree of care which it uses to protect its
                    Confidential Information of like importance and in no event
                    less than reasonable care, and shall be responsible and
                    liable for any use or disclosure of the Confidential
                    Information, including by its employees or other related
                    persons, in violation of these General Terms.
                  </ol>
                  <ol>
                    <strong>2.</strong> The Party shall immediately notify the
                    other Party of any unauthorized use or disclosure or
                    suspected unauthorized use or disclosure of Confidential
                    Information.
                  </ol>
                  <ol>
                    <strong>3.</strong> The obligations outlined in this section
                    shall not apply to information that the Receiving Party can
                    demonstrate, through clear and convincing evidence: was
                    already known to the Receiving Party without an obligation
                    of confidentiality at the time of disclosure hereunder; was
                    generally available to the public at the time of its
                    disclosure to the Receiving Party hereunder; became
                    generally available to the public after its disclosure other
                    than through an act or omission of the Receiving Party in
                    breach of these General Terms; or was subsequently, lawfully
                    and independently disclosed to the Receiving Party by a
                    person other than the Disclosing Party, not in violation of
                    the confidentiality agreement, arrangement or understanding
                    with such person.
                  </ol>
                  <ol>
                    <strong>4.</strong> If any disclosure of the Confidential Information is
                    required by you under applicable law, you shall provide us
                    reasonable notice and opportunity to contest the need for
                    such disclosure or to seek a protective order therefor. If
                    we fail to contest the need for such disclosure or to obtain
                    a protective order, you may disclose only that portion of
                    the Confidential Information that is legally so required to
                    be disclosed, provided that any Confidential Information so
                    disclosed shall maintain its confidentiality protection for
                    all purposes other than such legally required disclosure.
                  </ol>
                  <ol>
                    <strong>5.</strong> You shall acknowledge and agree that the disclosure of
                    information that may be considered confidential to competent
                    authorities and subcontractors of the Company as well as
                    other persons engaged/contacted by the Company for the
                    provision of Services/compliance with applicable
                    legislation, shall not be considered as a breach of the
                    confidentiality obligations.
                  </ol>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TermsCondition;
