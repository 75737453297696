import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import { toast } from "react-toastify";
import EmailVerified from "./EmailVerified";
import { getAuth, sendEmailVerification } from "firebase/auth";
import KycForm2 from "./KycForm2";
import { injectModels } from "../../../redux/injectModels";

const KycForm1 = (props) => {
  const [emailverified, setEmailVerified] = useState(false);
  const { currentUser } = useAuth();
  const [docdata, setdocData] = useState([]);
  const [activeForm, setactiveForm] = useState(false);
  const auth = getAuth();

  const userId = currentUser && currentUser.uid;

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      setdocData(data);
    } else {
      // console.log("No such document!");
    }
  };
  useEffect(() => {
    if (currentUser != null) {
      getData();
      if (currentUser.emailVerified) {
        setEmailVerified(true);
      }
      // setLoading(false);
    }
  }, [currentUser]);

  // console.log(docdata,"docdata");

  const submit = async () => {
    if (currentUser["emailVerified"]) {
      toast.success("Your Email is already verified Go ahead!", {
        position: toast.POSITION.TOP_CENTER,
      });
      setactiveForm(true);
    } else {
      props.application.setLoading(true)
      await sendEmailVerification(auth.currentUser)
        .then(() => {
          props.application.setLoading(false)
          // Email verification sent!
          toast.success("Email Sent!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setactiveForm(true);
          // ...
        })
        .catch((e) => {
          toast.error("too many attempts trying after some time", {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <React.Fragment>
      <div className="form-content">
        {!emailverified ? (
          <React.Fragment>
            {!activeForm ? (
              <React.Fragment>
                <div className="col-md-8">
                  <div className="merchant-kyc-div">
                    <h2>CONTACT INFO</h2>
                    <form>
                      <div className="form-group">
                        <label>
                          Contact name
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <select id="disabledSelect" className="form-control">
                          <option>{docdata.name}</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Contact Email
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <select id="disabledSelect" className="form-control">
                          <option>{docdata.email}</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Contact Number
                          <span style={{ color: "#ff0000" }}>*</span>
                        </label>
                        <select id="disabledSelect" className="form-control">
                          <option>{docdata.phone}</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className="ftr-form">
                    <span className="span-link">
                      We need to verify your Email
                    </span>
                    <button
                      className="save-next btn btn-primary Button"
                      onClick={() => submit()}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <EmailVerified />
            )}
          </React.Fragment>
        ) : (
          <KycForm2 />
        )}
      </div>
    </React.Fragment>
  );
};

export default injectModels(['application']) (KycForm1);


/* file created by sourav mishra */