import React from "react";

const Intro = () => {
  return (
    <section className="whta-crypto">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="whta-crypto2">
              <img alt='' src="/assets/img/about-image-1.png" />
            </div>
          </div>

          <div
            className="col-md-8"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div className="whta-crypto2">
              <h6>What is DotPay?</h6>
              <p>
                DotPay is a payment processor for digital currencies, similar to
                payment processors, gateways, and bank credit cards. DotPay
                enables you to accept crypto payments for your businesses.
              </p>
              <p>
                Whether you’re a merchant, trader, developer, or someone who is
                futuristic about blockchain technology and looking to integrate
                cryptocurrency payments into your business - everyone can
                benefit from the DotPay payment processor.
              </p>
            </div>
            {/* <div className="row">
                    <div className="col-md-6">
                        <div className="crypto-sub">
                            <p><i className="fa-solid fa-arrow-right-long pr-2"></i> An innovative hybrid proof-of-work</p>
                            <p><i className="fa-solid fa-arrow-right-long pr-2"></i> An innovative hybrid proof-of-work</p>
                            <p><i className="fa-solid fa-arrow-right-long pr-2"></i> An innovative hybrid proof-of-work</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="crypto-sub">
                            <p><i className="fa-solid fa-arrow-right-long pr-2"></i> An innovative hybrid proof-of-work</p>
                            <p><i className="fa-solid fa-arrow-right-long pr-2"></i> An innovative hybrid proof-of-work</p>
                            <p><i className="fa-solid fa-arrow-right-long pr-2"></i> An innovative hybrid proof-of-work</p>
                        </div>
                    </div>
                </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
