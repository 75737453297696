import React from "react";
import { Link } from "react-router-dom";

const Api = () => {
  return (
    <div className="api-key">
      <h1>API Keys</h1>

      <h3>Getting Started</h3>
      <ul>
        <li>
          These instructions will get you a copy of the project up and running
          on your local machine for development and testing purposes.
        </li>
        <li>
          See deployment for notes on how to deploy the project on a live
          system. We have presented the installation process stepwise for the
          same below. Kindly follow the same:
        </li>
      </ul>

      <h3>Prerequisites</h3>
      <li>This project requires NodeJS (version 11 or later) and NPM.</li>
      <li>
        To make sure you have them available on your machine, try running the
        following command.
      </li>
      <li>
        To make sure you have them available on your machine, try running the
        following command.
      </li>
      <pre>
        <code>
          {`
           "react-scripts": "4.0.3",
          `}
        </code>
      </pre>

      <h3>Installation</h3>
      <ul>
        <li>To install and set up the library, run</li>
        <li>$ npm install dot-pay</li>
        <li>$ yarn add dot-pay</li>
      </ul>

      <h3>Using</h3>
      <h5>steps</h5>
      <ul>
        <li>import WalletConnect from 'dot-pay';</li>
        <li>You need to pass below data sets in Properties:</li>
        <li>passed some properties in WalletConnect Component</li>{" "}
      </ul>

      <pre>
        <code>
          {`import WalletConnect from "dot-pay";
          function App() {
            return;
            <WalletConnect
              amount={"10000000000000000000000"}
              merchantContractAddress={"0x000000000000000000000000"}
              billingLabel={"buisnessName"}
              isBNB={false}
              tokenAddress={"0x0000000000000000000000000000"}
              DotBankAddress={"0x14D231D3b8faB41091848DD4342E7c603C571acC"}
            />;`}
        </code>
      </pre>

      <b>Getting Your Merchant Contract Address From Profile</b>
      <ul>
        <li>
          Connect Your Wallet using Metamask. When the Payment card is on the
          screen.
        </li>
        <li>
          Now you need to Approve Your Payment by clicking on the “Approve
          Button”
        </li>
        <li>
          Once the Payment is approved now click on the “Pay Button” for the
          payment completion.
        </li>
        <li>You will get a pop-up once the payment is completed.</li>{" "}
      </ul>

      <div>
        <div className="contact-direct">
          <Link to="/contact">
            Kindly get in touch in case you face issues during the installation
            process.
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

export default Api;
