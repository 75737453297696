import store from '../../store';
import { getMerchantTransactions, setLoading, getMerchantContractAddress ,getMerchantBnbBalance, getCustomTokenBalance,getTokenName,WithdrawToken,WithdrawBNB} from './action';


const obj = {

    setLoading:(val, message)=>store.dispatch(setLoading(val, message)),
    getMerchantTransactions:(merchantContractAddress)=>store.dispatch(getMerchantTransactions(merchantContractAddress)),
    getMerchantContractAddress:(userId)=>store.dispatch(getMerchantContractAddress(userId)),
    getMerchantBnbBalance:(merchantContractAddress)=>store.dispatch(getMerchantBnbBalance(merchantContractAddress)),
    getCustomTokenBalance:(merchantContractAddress,tokenAddress)=>store.dispatch(getCustomTokenBalance(merchantContractAddress,tokenAddress)),
    getTokenName:(tokenAddress)=>store.dispatch(getTokenName(tokenAddress)),
    WithdrawBNB:(merchantContractAddress,tokenAmount)=>store.dispatch(WithdrawBNB(merchantContractAddress,tokenAmount)),
    
    WithdrawToken:(merchantContractAddress,tokenAddress,tokenAmount)=>store.dispatch(WithdrawToken(merchantContractAddress,tokenAddress,tokenAmount)),

}

export default obj;
