import React from "react";
import { useState ,useEffect} from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase/config";
import KycForm2 from "./KycForm2";
import { injectModels } from "../../../redux/injectModels";

const EmailVerified = (props) => {

  const { currentUser } = useAuth();
  const [docdata, setdocData] = useState([]);
  const [activeForm, setActiveForm] = useState(false);

  const userId = currentUser && currentUser.uid;

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      setdocData(data);
     
    } else {
      // console.log("No such document!");
    }
  };
  
  useEffect(() => {
    if (currentUser != null) {
      getData();
    }
  }, [currentUser]);

  // const formActivation = () => {
  //   setActiveForm(true);
  // };
  

  const handleSubmit = (e) =>{
    e.preventDefault();
    console.log(currentUser,"currentUser")
    window.location.reload();
    // if(currentUser!=null){
    //   props.application.setLoading(true)
    //    console.log(currentUser.emailVerified,"emailverification");
    //     if(currentUser['emailVerified']){
    //       formActivation();
    //     }else{
    //       props.application.setLoading(false)
    //       toast.error("please verify your Email id", {
    //         position: toast.POSITION.TOP_CENTER,
    //       });
    //     }
    //   } else {
    //     props.application.setLoading(false)
    //   toast.error("please verify your Email id", {
    //       position: toast.POSITION.TOP_CENTER,
    //     });
    //   }
      }


  return (
    <React.Fragment>
    <div className="form-content">
         { !activeForm ?
    <React.Fragment>
      <section className="form-content">
            <div className="col-md-8">
              <div className="merchant-kyc-div ">
                <h2>Email verification</h2>
                <form className="mt-1" onClick={handleSubmit}>
                  <div className="col-md-12">
                 <div>We have send you an verification email over the 
                    Email id :
                    <span className="span-email">{docdata.email} </span> please verify  by clicking 
                    the link provided in email. once verified, click the
                    the button below </div>
                  </div>
                  <div className="submit-button">
                    <button type="data" className="save-next btn btn-primary Button" >
                    Email Verified &nbsp;
                    </button>
                  </div>
                </form>
              </div>
           
            </div>
      </section>
    </React.Fragment>
   :
   <KycForm2 />
  } 

</div>
</React.Fragment>
);
};

export default injectModels(['application'])(EmailVerified) ;
