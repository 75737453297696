export const routes = {
    DEFAULT: "/",
    SIGNUPONE:"/signup",
    NOT_FOUND:"/404-not-found",
    EXPLORE:"/explore",
    CONTACT:"/contact",
    FAQ:"/faq",
    LOGIN:'/login',
    FORGOTPASSWORD:'/forgot-password',
    RESETPASSWORD:'/reset-Password',
    DASHBOARD:'/dashboard',
    PRIVACY:'/privacy-policy',
    OFFERS:'/offers',
    PROFILE:'/profile',
    EDITPROFILE:'/edit-profile',
    PAYMENTS:'/payments',
    SUBSCRIPTIONS:'/subscription',
    BLOGS:'/blogs',
    TERMCONDITIONS:'/terms-and-conditions',
    MISSION:'/our-mission',
    API:'/api',
    ABOUTUS:'/about-us',
    KYCFORM1:'/kyc-contact',
    KYCFORM2:"/kyc-form-buisness-overview",
    KYCFORM3:'/KYC-buisness-details',
    EMAILVERIFIED:"/email-verification",
    PHONEVERIFIED:"/phone-verification",
    ERROR:"*"
};