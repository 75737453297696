import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import { Routes } from './constants';
import {JWT} from './shared';

const CustomAuthRoute = ({component:Component, ...rest}) => {
    const isValidToken = JWT.isValidToken();
    // console.log(isValidToken,"isValidToken");
    
//     const userJson = localStorage.getItem("user");
//    const user = userJson ? JSON.parse(userJson) : null; 
   return (
        <Route {...rest} render={(props)=>{
            return (
                <React.Fragment>
                  {isValidToken ? <Redirect to={Routes.DASHBOARD} /> : <AuthLayout><Component {...props} /></AuthLayout>}
                </React.Fragment>
            )
        }} />
    );
};

export default CustomAuthRoute;