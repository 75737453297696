import React, { useState ,useEffect } from "react";
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = RegExp(
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$/
);
const nameRegex = /^[a-zA-Z ]{2,30}$/;

const Contact = (props) => {
  const [firstname, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [firstnameError, setFirstNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const handleFirstNameChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setFirstNameError("Your Name is required");
    } else if (!nameRegex.test(val)) {
      setFirstNameError("Enter a valid name");
    } else {
      setFirstNameError("");
    }
    setFirstName(val.trim());
  };

  const handlePhoneChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (e.target.value.length > e.target.maxLength)
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    if (val.trim() === "") {
      setPhoneError("Phone Number is required"); //validations for phone
    } else if (!phoneRegex.test(val)) {
      setPhoneError("Enter a valid Phone Number");
    } else {
      setPhoneError("");
    }
    setPhone(val.trim());
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Enter a valid Email ");
    } else {
      setEmailError("");
    }
    setEmail(val.trim());
  };

  const handleSubjectChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setSubjectError("Subject is required");
    } else {
      setSubjectError("");
    }
    setSubject(val.trim());
  };

  const handleMessageChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
    setMessage(val);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    if (firstname === "") {
      setFirstNameError("Your Name is required");
      valid = false;
    } else {
      setFirstNameError("");
    }
    if (phone === "") {
      setPhoneError("Phone is required");
      valid = false;
    } else {
      setPhoneError("");
    }

    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Enter a Valid Email");
      valid = false;
    } else {
      setEmailError("");
    }

    if (subject === "") {
      setSubjectError("Subject is required");
      valid = false;
    } else {
      setSubjectError("");
    }

    if (message === "") {
      setMessageError("Message is required");
      valid = false;
    } else {
      setMessageError("");
    }

    if (!valid) {
      return;
    } else {
      props.application.setLoading(true);
      setError("");
      var data = {
        webservice_type: "inquiry",
        summary_work_required: "This is a lead from dotcoin",
        message: message,
        source_website: "https://www.dotsquares.com/lp/hubspot-partner-agency/",
        source: "dotcoin.thedotverse.com",
        search_url: "https://dotcoin.thedotverse.com",
        search_keywords: "",
        quote_for: "",
        phone: phone,
        lead_status: "Lead",
        lead_quality: "M",
        lead_name: "Lead from dotcoin.thedotverse.com website by " + firstname,
        gdpr_offers_and_events: 1,
        gdpr_marketing: 1,
        gdpr_longitude: "77.2167",
        gdpr_latitude: "28.6667",
        gdpr_ip: "",
        gdpr_important_technical_updates: 1,
        gdpr_all: 1,
        email: email,
        detail_specification: "",
        country: "",
        contact_name: firstname,
        company_name: subject,
        company_address: "",
        referral_url: "",
        technology: "blockchain",
      };
      try {
        fetch(
          "https://uatdsc.dotsquares.com/dscwebservices/Webhookgeneral.php",
          {
            // fetch("https://dscwebservices.dotsquares.com/Webhookgeneral.php", {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        ).then((response) => {
          console.log(response);
      props.application.setLoading(false);
      e.target.reset();
      toast.success("Thank you for your query! DotPay customer service team will contact you shortly.", {
        position: toast.POSITION.TOP_CENTER,
      });
      window.setTimeout(function(){window.location.reload()},4000)
        });
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content d-flex align-items-center">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="contact-us">
        <div className="container">
          <div className="row">
            <div className="col-md-5 d-flex align-items-center">
              <div className="contact-form">
                <img src="/assets/img/contact-us.png" alt="" />
              </div>
            </div>
            <div className="col-md-7 ">
              {/* <div className="top-contact">
               <div className="row">
                 <div className=" col-xl-6 col-lg-6 col-md-12">
                  <div className="email-us">
                     <div className="email-us-icon"> 
                  <a href="true"><i className="fa-solid fa-envelope"></i></a>
                   </div> 
                   <div className="ib-r">
                     <h6>Email Us</h6>
                     <p>contactsourav@example.com</p>
                   </div>  
                  </div>   
 
                 </div>
                  <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="email-us">
                     <div className="email-us-icon"> 
                  <a href="true"><i className="fa-solid fa-phone"></i></a>
                   </div> 
                   <div className="ib-r">
                     <h6>Call Us</h6>
 
                     <p>+0000 0000 0000</p>
                   </div>  
                  </div>   
 
                 </div>
                 </div>
                 </div> */}
              <div className="contact-form-content new-contact">
                <h3>Get In Touch</h3>
                <form className="mt-1" method="post" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="searchformfld">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" "
                          onChange={handleFirstNameChange}
                        />
                        <label>Your Name</label>
                        {firstnameError && (
                          <span className="error">{firstnameError}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="searchformfld">
                        <input
                          type="number"
                          className="form-control"
                          placeholder=" "
                          onChange={handlePhoneChange}
                          maxLength={16}
                        />
                        <label>Phone Number</label>
                        {phoneError && (
                          <span className="error">{phoneError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="searchformfld">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" "
                          onChange={handleEmailChange}
                        />
                        <label>Email Address</label>
                        {emailError && (
                          <span className="error">{emailError}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="searchformfld">
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" "
                          onChange={handleSubjectChange}
                        />
                        <label>Subject</label>
                        {subjectError && (
                          <span className="error">{subjectError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={message && message.length>0 ? "searchformfld text-content-override" : "searchformfld"}>
                    <textarea
                      className="form-control"
                      rows={4}
                      cols={50}
                      maxLength={200}
                      placeholder=" "
                      onChange={handleMessageChange}
                    />
                    <label>Message</label>
                    {messageError && (
                      <span className="error">{messageError}</span>
                    )}
                  </div>
                  {successMessage && (
                    <div className="col-xs-12 col-md-12 col-lg-12">
                      <div className="alert alert-success">
                        {successMessage}
                      </div>
                    </div>
                  )}
                  {error && (
                    <div className="col-xs-12 col-md-12 col-lg-12">
                      <div className="alert alert-danger">{error}</div>
                    </div>
                  )}
                  <div className="submit-button">
                    <button type="submit" className="btn btn-primary">
                      Submit &nbsp;
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default injectModels(["application"])(Contact);
