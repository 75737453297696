import React from 'react'

const Offer = () => {
  console.log("offer")
  return (
   <div className="hello-div">
    There are no offers yet!!
    Start creating new offers now.
   </div>
  )
}

export default Offer