import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { CropImage } from "../Cropper";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../Firebase/config";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { toast } from "react-toastify";
import MyLoader from "../Loader/loader";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const EditProfile = () => {
  const storage = getStorage();
  const { currentUser } = useAuth();
  const [loader, setLoader] = useState("");
  const [docdata, setdocData] = useState({
    name: "",
    phone: "",
    username: "",
    email: "",
    image: "",
  });
  const [image, setImage] = useState(null);
  const userId = currentUser && currentUser.uid;
  const [avtarModelOpen, setAvtarModelOpen] = useState(false);
  const avatarFileRef = React.useRef();
  const [imageLoading, setImageLoading] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  // const [usernameError, setUserNameError] = useState("");
  // const userNameRegex = /^[A-Za-z][A-Za-z0-9_]{7,29}$/;

  const handelAvtarFile = (value, event) => {
    if (value) {
      //handling image file
      console.log(value, "value");
      setImage(value);
      setAvtarModelOpen(true);
    }
  };

  const handleChange = (event) => {


    // console.log(event.target);
    setdocData({ ...docdata, [event.target.name]: event.target.value });
  };

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      console.log(data);
      setdocData(data);
      // setUser(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  };
  useEffect(() => {
    if (currentUser != null) {
      getData();
    }
  }, [currentUser]);

  const getCroppedImageAvtar = async (blob) => {
    if (blob) {
      try {
        setImageLoading(true);
        const imageRef = ref(
          storage,
          "merchants/" + docdata.email + "/profile" + Date.now()
        );
        const snapshot = await uploadBytes(imageRef, blob);
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log(downloadURL, "image");
        setAvatarUrl(downloadURL);
        setImageLoading(false);
      } catch (error) {
        console.log(error);
        setImageLoading(false);
      }
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      docdata.username === "" ||
      docdata.name === "" ||
      docdata.phone === ""
    ) {
      toast.error("Please fill the required fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    try {
      const docRef = doc(db, "Merchants", userId);
      await updateDoc(docRef, {
        name: docdata.name,
        phone: docdata.phone,
        username: docdata.username,
        image:avatarUrl ? avatarUrl : docdata.image
      });
      setLoader(false);
      toast.success("your detail Updated successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error);
      toast.error("Could not update details", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  console.log(docdata,"sklflkhfj")

  return (
    <React.Fragment>
      <div className="icon-menu">
        <Link
          to={routes.PROFILE}
          className="fa fa-arrow-left"
          aria-hidden="true"
        ></Link>
      </div>
      <div className="page-content page-container" id="page-content">
        <div className="padding">
          <div className="row container d-flex justify-content-center">
            <div className="col-xl-12 col-md-12 mt-1">
              <div className="card user-card-full">
                <div className="row m-l-0 m-r-0">
                  <div className="col-md-5 col-sm-12 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25">
                        {/* <img
                          src={docdata.image}
                          className="img-radius"
                          alt=""
                        /> */}
                        <input
                          hidden
                          ref={avatarFileRef}
                          type="file"
                          name="profileImage"
                          // onChange={handleImageChange}
                          onChange={(e) => handelAvtarFile(e.target.files[0])}
                        />
                        <div
                          className="box_content asset-uploader mb-3"
                          onClick={(e) => {
                            e.preventDefault();
                            avatarFileRef.current.click();
                          }}
                        >
                          <img
                            className="img-radius"
                            src={
                              imageLoading
                                ? "/assets/img/loader.svg"
                                : avatarUrl
                                ? avatarUrl
                                : docdata.image
                            }
                            alt=""
                          />
                          <button> Click on Image To Upload</button>
                        </div>
                      </div>
                      {/* <button  onChange={(e) => handelAvtarFile(e.target.files[0])} >  <i className="fa-solid fa-cloud-arrow-up"></i> &nbsp; Upload Image</button> */}
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="card-block">
                      <form>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            User Name
                          </label>
                          <input
                            id="username"
                            type="text"
                            name="username"
                            className="form-control"
                            value={docdata.username}
                            required="required"
                            onChange={handleChange}
                          />
                        </div>
                        {/* {usernameError && (
                          <span className="error">{usernameError}</span>
                        )} */}
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Name
                          </label>
                          <input
                            id="name"
                            type="text"
                            name="name"
                            className="form-control"
                            value={docdata.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            value={docdata.email}
                            readOnly={true}
                            // onChange={handleEmail}
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Phone
                          </label>
                          <input
                            id="phone"
                            type="text"
                            name="phone"
                            className="form-control"
                            aria-describedby="emailHelp"
                            value={docdata.phone}
                            readOnly={true}
                          />
                        </div>

                        {/* <div className="mb-3">
                        <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                        <input type="password" className="form-control" id="exampleInputPassword1" onChange={handleChange}/>
                    </div> */}
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => updateProfile(e)}
                        >
                          Submit
                          {loader === true ? <MyLoader /> : ""}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CropImage
        isOpen={avtarModelOpen}
        file={image}
        getCroppedImage={getCroppedImageAvtar}
        aspectRatio={1}
      />
    </React.Fragment>
  );
};

export default EditProfile;

/* file created by sourav mishra  on 22/06/2022 */
