import {login,logout,register} from './action';
import store from '../../store';

const model = {
    userLogin: (email, password)=>{
        return store.dispatch(login(email, password));
      
    },
    logout: () => { return store.dispatch(logout());  },
    register: () => { return store.dispatch(register());}
}

export default model;