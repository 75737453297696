import React from 'react'
import { DashboardFooter, DashboardHeader, SideBar } from '../components';

const DashboardLayout = props => {
  return (
          <React.Fragment>
          <DashboardHeader />
          <SideBar />
          <main id="main" className="main">
           {props.children}
           </main>
          <DashboardFooter />
       </React.Fragment>
  )
}

export default DashboardLayout;