import React, { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase/config";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { injectModels } from "../../redux/injectModels";

const Profile = (props) => {
  const { currentUser } = useAuth();
  const [docdata, setdocData] = useState([]);
  const [docdata2, setdocData2] = useState([]);
  const userId = currentUser && currentUser.uid;

  const getData = async () => {
    const docRef = doc(db, "Merchants", userId);
    const docRef2 = doc(db, "Kyc", userId);
    props.application.setLoading(true);
    const docSnap = await getDoc(docRef);
    const docSnap2 = await getDoc(docRef2);
    if (docSnap.exists()) {
      const data = docSnap.data() && docSnap.data();
      const data2 = docSnap2.data() && docSnap2.data();
      console.log(data);

      props.application.setLoading(false);
      setdocData(data);
      if (docSnap2.exists()) {
        setdocData2(data2);
        console.log(data2);
      } else {
        setdocData2("Yet not verified");
      }
    } else {
      console.log("No such document!");
    }
  };

  const merchantAddress = () => {
    if (docdata.merchantContractAddress) {
      const m = docdata.merchantContractAddress.substr(
        docdata.merchantContractAddress.length - 40
      );
      return "0x" + m;
    } else {
      return "Please Wait for Verification";
    }
  };

  useEffect(() => {
    if (currentUser != null) {
      getData();
    }
  }, [currentUser]);

  return (
    <React.Fragment>
      <div className="page-content page-container" id="page-content">
        <div className="padding">
          <div className="row container d-flex justify-content-center">
            <div className="col-xl-12 col-md-12 mt-1">
              <div className="card user-card-full">
                <div className="row m-l-0 m-r-0">
                  <div className=" col-xl-4 col-lg-4 col-md-4 col-sm-12 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25">
                        <img
                          src={docdata.image}
                          className="img-radius"
                          alt=""
                        />
                      </div>
                      <h6 className="f-w-600">{docdata.username}</h6>
                      <p>{docdata.name}</p>
                      <i className=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16" />
                    </div>
                  </div>
                  <div className=" col-xl-8 col-lg-8 col-md-8 col-sm-12">
                    <div className="card-block">
                      <h6 className="m-b-20 p-b-5 b-b-default f-w-600 test-icon">
                        Information{" "}
                        <Link
                          to={routes.EDITPROFILE}
                          className="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></Link>
                      </h6>
                      <div className="row email-content-heading">
                        <div className="col-sm-6 ">
                          <p className="m-b-10 f-w-600">Email</p>
                          <h6 className="text-muted f-w-400">
                            {docdata.email}
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Phone</p>
                          <h6 className="text-muted f-w-400">
                            {docdata.phone}
                          </h6>
                        </div>
                      </div>
                      <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                        Merchants
                      </h6>
                      <div className="row email-content-heading">
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Name</p>
                          <h6 className="text-muted f-w-400">{docdata.name}</h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">userId</p>
                          <h6 className="text-muted f-w-400">{userId}</h6>
                        </div>
                      </div>
                      <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                        wallet
                      </h6>
                      <div className="row email-content-heading">
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Contract Address</p>
                          <h6 className="text-muted f-w-400">
                            {merchantAddress()}
                          </h6>
                        </div>
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Wallet Address</p>
                          <h6 className="text-muted f-w-400">
                            {docdata2.WalletAddress
                              ? docdata2.WalletAddress
                              : "Please wait For Verification"}
                          </h6>
                        </div>
                        <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                          Verifier
                        </h6>

                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Verifier Address</p>
                          <h6 className="text-muted f-w-400">
                            {docdata.verifiedBy
                              ? docdata.verifiedBy
                              : "Please wait For Verification"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["application"])(Profile);

/* file created by sourav mishra  on 22/06/2022 */
