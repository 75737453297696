import React, { useEffect, useState } from "react";
import { injectModels } from "../../redux/injectModels";
import { useAuth } from "../../contexts/AuthContext";
import { NavLink } from "react-router-dom";

const Dashboard = (props) => {
  const { currentUser } = useAuth();
  const [balance, setBalance] = useState("");

  const uid = currentUser && currentUser.uid;

  const getContractAddress = async () => {
    const userId = uid && uid;
    try {
      if (userId) {
        props.application.setLoading(true);
        const address = await props.application.getMerchantContractAddress(
          userId
        );
        props.application.setLoading(false);
        return address;
        // setContractAddreess(address);
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const getBnbBalance = async () => {
    const address = await getContractAddress();
    try {
      if (address) {
        props.application.setLoading(true);
        if (address === "0x0000000000000000000000000000000000000000") {
          setBalance("0");
          props.application.setLoading(false);
        } else {
          props.application.setLoading(true);
          const transaction = await props.application.getMerchantBnbBalance(
            address
          );
          const bnb = transaction / 10e17;
          props.application.setLoading(false);
          setBalance(bnb);
        }
       
      }
    } catch (err) {
      props.application.setLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  useEffect(() => {
    getBnbBalance();
  }, [currentUser, balance]);

  return (
    <React.Fragment>
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav></nav>
      </div>
      {/* End Page Title */}
      <div className="dash-section dashboard">
        <div className="row">
          {/* Left side columns */}
          <div className="col-lg-12">
            <div className="row">
              {/* Sales Card */}
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card info-card sales-card">
                  <NavLink to="/payments">
                    <div className="card-body">
                      <h5 className="card-title">
                        Transactions <span>| Recent</span>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                          <i className="fa-solid fa-exchange" />
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
              {/* End Sales Card */}
              {/* Revenue Card */}
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">Total Balance<span>| BNB</span></h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-dollar-sign" />
                      </div>
                      <div className="ps-3">
                        <h6>{balance}</h6>{" "}
                        <span className="text-muted small pt-2 ps-1">
                          increase
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Revenue Card */}
              {/* Customers Card */}
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card info-card customers-card">
                  <NavLink to="/api">
                    <div className="card-body">
                      <h5 className="card-title">
                        API KEYS <span>| After Verified</span>
                      </h5>
                      <div className="d-flex align-items-center">
                        <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-file-contract"></i>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="card info-card customers-card">
                  <NavLink to="/profile">
                  <div className="card-body">
                    <h5 className="card-title">
                      Profile <span>| view</span>
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="fa-solid fa-user-group" />
                      </div>
                 
                    </div>
                  </div>
                  </NavLink>
                  
                </div>
              </div>
              {/* End Customers Card */}
              {/* Reports */}
              {/* Recent Sales */}
              {/* Top Selling */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["auth", "application"])(Dashboard);

/* file created by sourav mishra */
