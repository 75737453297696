import React, { useEffect } from "react";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="slider-content  d-flex align-items-center">
              <h2>FREQUENTLY ASKED QUESTIONS:</h2>
            </div>
          </div>
        </div>
      </section>
      <section className="faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div id="accordion">
                <div className="card question">
                  <div className="card-header faq-collapse" id="headingOne">
                    <h5 className="mb-0">
                      <a
                        href="true"
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1.How do I accept crypto payments?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body faq-btm">
                      To acknowledge payments in cryptocurrency, you'll need to
                      set up wallets for every coin you want to accept and
                      establish a checkout process. Alternatively, you can
                      utilize 3rd party payment gateway to gather get crypto
                      payments for yourself and multiple other tools. Learn how
                      to accept crypto payments with a crypto payment processor
                      Dotpay
                    </div>
                  </div>
                </div>
                <div className="card question">
                  <div className="card-header faq-collapse" id="headingTwo">
                    <h5 className="mb-0">
                      <a
                        href="true"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2.How do you pay in crypto?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body faq-btm">
                      When at checkout, select the cryptocurrency you need to
                      use for payments. Then, open your wallet and output the QR
                      code or reorder the accepting wallet's location
                      physically. Enter the mentioned sum and settle the
                      payments. Ensure that the coins are being sent to the
                      suitable blockchain network (e.g., ERC20 tokens ought to
                      be sent to the Ethereum Network only, BEP20 tokens to
                      Binance Smart Chain (BSC), etc.)
                    </div>
                  </div>
                </div>
                <div className="card question">
                  <div className="card-header faq-collapse" id="headingThree">
                    <h5 className="mb-0">
                      <a
                        href="true"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3.Should I accept cryptocurrency as payment?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body faq-btm ">
                      There are several benefits of accepting cryptocurrency
                      payments. These incorporate aster payments, lower
                      transaction costs, no risk of chargebacks, a more
                      extensive range of customers, and more. Learn all the
                      reasons why getting paid in crypto is worth considering
                    </div>
                  </div>
                </div>
                <div className="card question">
                  <div className="card-header faq-collapse" id="headingfour">
                    <h5 className="mb-0">
                      <a
                        href="true"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapsefour"
                        aria-expanded="false"
                        aria-controls="collapsefour"
                      >
                        4.How much do you charge?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapsefour"
                    className="collapse"
                    aria-labelledby="headingfour"
                    data-parent="#accordion"
                  >
                    <div className="card-body faq-btm ">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid.
                    </div>
                  </div>
                </div>
                <div className="card question">
                  <div className="card-header faq-collapse" id="headingfive">
                    <h5 className="mb-0">
                      <a
                        href="true"
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapsefive"
                        aria-expanded="false"
                        aria-controls="collapsefive"
                      >
                        5. How much do you charge?
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapsefive"
                    className="collapse"
                    aria-labelledby="headingfive"
                    data-parent="#accordion"
                  >
                    <div className="card-body faq-btm ">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life
                      accusamus terry richardson ad squid.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
